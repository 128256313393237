import * as React from 'react';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { StyledRoot } from './styles';
import LoginForm from '../../components/LoginFormV2';
import { LoginFormProps } from '../../components/LoginForm/schema';
import { signinRequest } from '../../api/authenticate';

const Login = (): React.ReactElement => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  const { authenticate, signin } = useAuthenticate();

  React.useEffect(() => {
    if (authenticate?.isAuthenticate) {
      navigate('/dash');
    }
  }, [authenticate, navigate]);

  const handleSubmit = async (values: LoginFormProps) => {
    try {
      setIsLoading(true);

      const data = await signinRequest(values);

      signin(data.token);
    } catch (error: any) {
      const message = 'Usuário ou senha invalidos!';
      toast(message, { type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledRoot>
      <LoginForm onSubmit={handleSubmit} isLoading={isLoading} />
    </StyledRoot>
  );
};

export default Login;
