import api from '../services/api';

export const getReportsRequests = async (token: string): Promise<any> => {
  const result = await api.get('/api/reports/requests', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getReportsUsers = async (token: string): Promise<any> => {
  const result = await api.get('/api/reports/users', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getReportsClients = async (token: string): Promise<any> => {
  const result = await api.get('/api/reports/clients', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getReportsClosings = async (token: string): Promise<any> => {
  const result = await api.get('/api/reports/closing', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getReportsTasks = async (token: string): Promise<any> => {
  const result = await api.get('/api/reports/tasks', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export default {
  getReportsRequests,
  getReportsUsers,
  getReportsClients,
  getReportsClosings,
  getReportsTasks,
};
