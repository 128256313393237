import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledEditContainer = styled('div')({
  position: 'absolute',
  right: 10,
  top: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  transition: '0.4s',
  padding: '0px 5px 5px 5px',
  boxSizing: 'border-box',
  borderRadius: 8,

  '&:hover': {
    background: '#cccccc66',
  },
});

export const StyledEditRequestContainer = styled('div')({
  position: 'absolute',
  right: 10,
  top: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  transition: '0.4s',
  gap: 8,
});

export const StyledEditRequestItem = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
  transition: '0.4s',
  padding: '0px 5px 5px 5px',
  boxSizing: 'border-box',
  borderRadius: 8,

  '&:hover': {
    background: '#cccccc66',
  },
});
