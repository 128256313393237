import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';

interface CardProps {
  path: string;
  labels: string[];
}

const Backbutton: React.FC<CardProps> = ({ path, labels }): ReactElement => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        width: '100%',
        padding: '16px 0px',
      }}
    >
      <IconButton onClick={() => handleClick()}>
        <KeyboardBackspaceIcon fontSize="large" htmlColor="#555" />
      </IconButton>
      {labels?.map((item, index) => (
        <React.Fragment key={index}>
          <div style={{ fontSize: 22, color: '#555' }}>{item}</div>
          {index < labels.length - 1 && (
            <div style={{ fontSize: 22, color: '#555' }}>/</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Backbutton;
