/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledRoot } from './styles';
import { addRequest } from '../../api/request';
import useAuthenticate from '../../recoil/hooks/authenticate';
import AddRequestForm from '../../components/AddRequestForm';
import Card from '../../components/Card';
import { getClients } from '../../api/client';
import { removeMask } from '../../utils/mask';
import Backbutton from '../../components/Backbutton';
import { getTask } from '../../api/task';
import DetailsInfo from '../../components/DetailsInfo';
import { formatDate } from '../../utils/date';

const AddRequest: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const queryTask = useMemo(() => {
    return queryParams.get('task') || undefined;
  }, [queryParams]);

  const [clients, setClients] = useState([]);
  const [task, setTask] = useState<any>();

  const uncompletedRequestKey = useMemo(() => {
    return task ? `uncompleted-request-task-${task.id}` : 'uncompleted-request';
  }, [task]);

  const getClientsData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getClients(authenticate.token);

        setClients(data?.clients || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getClientsData();
  }, [getClientsData]);

  const getTaskData = useCallback(
    async (taskId: string) => {
      try {
        if (authenticate.token) {
          const data = await getTask(taskId, authenticate.token);
          setTask(data?.task);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (queryTask) {
      getTaskData(queryTask);
    }
  }, [getTaskData, queryTask]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitAddRequest = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDate, values.startDateTime);
      const endDate = prepareDate(values.endDate, values.endDateTime);

      await addRequest(
        {
          ...values,
          startDate,
          endDate,
          food: removeMask(values.food),
          taxRoad: removeMask(values.taxRoad),
          parking: removeMask(values.parking),
          task: task?._id,
        },
        authenticate.token
      );

      localStorage.removeItem(uncompletedRequestKey);

      if (task) {
        navigate('/dash/tasks');
      } else {
        navigate('/dash/requests');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path={queryTask ? `/dash/tasks` : '/dash/requests'}
        labels={
          queryTask
            ? ['Tarefas', 'Concluir tarefa']
            : ['Atendimentos', 'Novo atendimento']
        }
      />

      {task && (
        <Card>
          <DetailsInfo
            isLoading={false}
            sections={[
              {
                rows: [
                  {
                    key: 'client',
                    subkey: 'name',
                    label: 'Cliente',
                  },
                  {
                    key: 'user',
                    label: 'Consultor',
                    transform: (user) =>
                      user.map((item: any) => item.name).join(', '),
                  },
                  {
                    key: 'createdBy',
                    subkey: 'name',
                    label: 'Criado por',
                  },
                  {
                    key: 'startDate',
                    label: 'Data',
                    transform: formatDate,
                  },
                  {
                    key: 'description',
                    label: 'Descrição',
                    isHtml: true,
                  },
                ],
                data: task,
                title: 'Tarefa',
              },
            ]}
          />
        </Card>
      )}

      <Card>
        <AddRequestForm
          isLoading={false}
          onSubmit={handleSubmitAddRequest}
          clients={clients}
          task={task}
        />
      </Card>
    </StyledRoot>
  );
};

export default AddRequest;
