import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

interface DotProps {
  color: string;
}

const Dot = styled.div<DotProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: relative;
  animation: ${pulse} 1s infinite;
`;

interface NotificationDotProps {
  color: string;
}

const NotificationDot: React.FC<NotificationDotProps> = ({ color }) => {
  return <Dot color={color} />;
};

export default NotificationDot;
