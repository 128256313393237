import React, { ReactElement } from 'react';

import { Typography } from '@mui/material';
import { StyledRoot } from './styles';

export interface DetailsSectionRowsProps {
  label: string;
  key: string;
  subkey?: string;
  isHtml?: boolean;
  hidden?: (data: any) => boolean;
  transform?: (text: any) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement;
}

export interface DetailsSectionProps {
  title?: string;
  rows: DetailsSectionRowsProps[];
  data: any;
}

interface Props {
  isLoading: boolean;
  sections: DetailsSectionProps[];
}

const DetailsInfo: React.FC<Props> = ({ sections }): React.ReactElement => {
  const handleValue = (
    data: any,
    key: DetailsSectionRowsProps
  ): string | ReactElement => {
    const value = key.subkey ? data?.[key.key]?.[key.subkey] : data?.[key.key];
    if (value === undefined || value === null || value === '') {
      return '-';
    }

    if (key.isHtml) {
      return (
        <div
          style={{ padding: 5, boxSizing: 'border-box' }}
          dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br/>') }}
        />
      );
    }

    if (key.transformBody) {
      return key.transformBody(data);
    }

    if (key.transformIcon) {
      return key.transformIcon(value);
    }

    if (key.transform) {
      return key.transform(value);
    }

    return value;
  };

  const handleValueToHidden = (
    data: any,
    key: DetailsSectionRowsProps
  ): string | ReactElement => {
    const value = key.subkey ? data?.[key.key]?.[key.subkey] : data?.[key.key];

    return value;
  };

  return (
    <StyledRoot>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        {sections.map((item, index) => (
          <div key={index}>
            {item.title && <Typography variant="h6">{item.title}</Typography>}

            {!!item.data &&
              item.rows
                ?.filter(
                  (row) => !row.hidden?.(handleValueToHidden(item.data, row))
                )
                .map((row, indexRow) => (
                  <div key={`${index}-${indexRow}`} style={{ color: '#555' }}>
                    {row.label}:{' '}
                    <span style={{ color: '#000' }}>
                      {handleValue(item.data, row)}
                    </span>
                  </div>
                ))}
          </div>
        ))}
      </div>
    </StyledRoot>
  );
};

export default DetailsInfo;
