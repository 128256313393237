/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { Avatar, AvatarGroup, Chip, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import TableTitle from '../../components/Table/TableTitle';
import { deleteTask, getTasks } from '../../api/task';
import { formatOnlyDateWithDayOfWeek } from '../../utils/date';
import Backbutton from '../../components/Backbutton';
import TableExpansive from '../../components/TableExpansive';
import TaskDetails from './TaskDetails';
import { formatRepeatOften } from './utils';
import NotFoundItems from '../../components/NotFoundItems';
import ConfirmModal from '../../components/ConfirmModal';
import SmallLoading from '../../components/SmallLoading';

const Tasks: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);

  const [search, setSearch] = useState('');

  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState('');

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token) {
        const data = await getTasks(authenticate.token);

        setTasks(data?.data || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleOpenDeleteModal = (id: string) => {
    setOpen(true);
    setDataToDelete(id);
  };

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteTask(authenticate.token, id);

        getData();
      }

      toast('Tarefa deletada com sucesso!', { type: 'success' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const handleEdit = (taskId: string, isInternal: boolean) => {
    if (isInternal) {
      navigate(`/dash/tasks/internal/finish?task=${taskId}`);
    } else {
      navigate(`/dash/tasks/finish?task=${taskId}`);
    }
  };

  const handleExpensive = (data: any) => {
    return (
      <TaskDetails
        data={data}
        handleOpenDeleteModal={() => handleOpenDeleteModal(data._id)}
        handleEdit={() => handleEdit(data._id, data.internal)}
        handleRedirectEdit={() => navigate(`/dash/tasks/${data._id}/edit`)}
      />
    );
  };

  const formatStatusIcon = (status: string): ReactElement => {
    if (status === 'todo') {
      return <Chip label="Pendente" color="default" />;
    }

    if (status === 'overdue') {
      return <Chip label="Atrasado" color="warning" />;
    }

    if (status === 'cancel') {
      return <Chip label="Cancelado" color="error" />;
    }

    return <Chip label="Finalizado" color="primary" />;
  };

  const formatUser = (user: any): ReactElement => {
    return (
      <AvatarGroup
        max={4}
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        {user.map((item: any) => (
          <Tooltip title={item.name} key={item.id} arrow>
            <Avatar key={item.id} alt={item.name} src="." />
          </Tooltip>
        ))}
      </AvatarGroup>
    );
  };

  const dataFiltered = useMemo(() => {
    if (!tasks) {
      return [];
    }

    if (!search) {
      return tasks;
    }

    const searchUppercase = search.toUpperCase();

    return tasks.filter((item: any) => {
      const hasUser = item.user.find(
        (user: any) =>
          user.name.toUpperCase().includes(searchUppercase) ||
          user.email.toUpperCase().includes(searchUppercase)
      );

      if (hasUser) {
        return true;
      }

      return (
        item.description.toUpperCase().includes(searchUppercase) ||
        item.client?.name?.toUpperCase()?.includes(searchUppercase)
      );
    });
  }, [search, tasks]);

  return (
    <React.Fragment>
      <StyledRoot>
        <div style={{ display: 'flex', flex: 1 }}>
          <Backbutton path="/dash" labels={['Tarefas']} />

          <TableTitle
            title=""
            search={{
              handleSearch: (value: string) => setSearch(value),
            }}
            button={{
              text: 'Nova tarefa',
              onAction: () => navigate('/dash/tasks/create'),
            }}
          />
        </div>

        {isLoading && <SmallLoading />}

        {!isLoading && (
          <TableExpansive
            header={{
              backgroundColor: '#38A658',
              color: '#fff',
              columns: [
                {
                  label: 'Usuário',
                },
                {
                  label: 'Cliente',
                },
                {
                  label: 'Data',
                },
                {
                  label: 'Recorrência',
                },
                {
                  label: 'Status',
                },
              ],
            }}
            body={{
              rows: [
                { key: 'user', transformIcon: formatUser },
                {
                  key: 'client',
                  subkey: 'name',
                  transform: (item) => item || 'Tarefa interna',
                },
                { key: 'startDate', transform: formatOnlyDateWithDayOfWeek },
                { key: 'repeatOften', transform: formatRepeatOften },
                { key: 'status', transformIcon: formatStatusIcon },
              ],
              options: [
                {
                  label: 'Editar',
                  onClick: (data) => navigate(`/dash/tasks/${data._id}/edit`),
                  disabled: (data) =>
                    !['todo', 'overdue'].includes(data.status),
                },
                {
                  label: 'Concluir tarefa',
                  onClick: (data) => handleEdit(data._id, data.internal),
                  disabled: (data) =>
                    !['todo', 'overdue'].includes(data.status),
                },
                {
                  label: 'Deletar',
                  onClick: (data) => handleOpenDeleteModal(data._id),
                },
              ],
              showNotification: (item) =>
                item.status === 'overdue' ? '#ff3d00' : '',
            }}
            data={dataFiltered}
            handleExpansive={handleExpensive}
          />
        )}

        {!isLoading && !dataFiltered.length && (
          <NotFoundItems
            label={
              tasks.length
                ? 'Nenhuma tarefa encontrada para essa busca!'
                : undefined
            }
          />
        )}
      </StyledRoot>
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(dataToDelete)}
        title="Deletar tarefa"
        message="Deseja confirmar a exclusão da tarefa?"
      />
    </React.Fragment>
  );
};

export default Tasks;
