import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const StyledContentCards = styled('div')({
  display: 'flex',
  gap: 16,
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  // alignItems: 'center',
  // background: 'red',
  width: '100%',
  // marginTop: 16,
  // marginBottom: 64,
});

export const StyledContentRequests = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
});

export const StyledContentCard = styled('div')({
  flex: 1,
});

export const StyledContentGraph = styled('div')({
  flex: 2,
});
