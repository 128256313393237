import { RecurrencyRequestEnum } from '../../components/AddTaskForm/schema';

export enum TaskStatusEnum {
  TODO = 'todo',
  DONE = 'done',
  OVERDUE = 'overdue',
  CANCEL = 'cance',
}

export const formatStatusTask = (status: TaskStatusEnum): string => {
  const options = {
    [TaskStatusEnum.TODO]: 'Pendente',
    [TaskStatusEnum.DONE]: 'Finalizada',
    [TaskStatusEnum.OVERDUE]: 'Atrasada',
    [TaskStatusEnum.CANCEL]: 'Cancelada',
  };

  return options[status];
};

export const formatRepeatOften = (repeat?: RecurrencyRequestEnum): string => {
  if (!repeat) {
    return 'Não se repete';
  }

  const options = {
    [RecurrencyRequestEnum.DAILY]: 'Todos os dias',
    [RecurrencyRequestEnum.WEEKLY]: 'Semanal',
    [RecurrencyRequestEnum.MONTHLY]: 'Mensal',
    [RecurrencyRequestEnum.YEARLY]: 'Anual',
  };

  return options[repeat];
};
