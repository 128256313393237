import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import BusinessIcon from '@mui/icons-material/Business';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentCard,
  StyledContentCards,
  StyledContentRequests,
  StyledRoot,
} from './styles';
import CardHome from '../../components/CardHome';
import RenderRequests from '../Requests/RenderRequests';
import TableTitle from '../../components/Table/TableTitle';
import CardGraph from '../../components/CardGraph';
import useAuthenticate from '../../recoil/hooks/authenticate';
import {
  getReportsClients,
  getReportsClosings,
  getReportsRequests,
  getReportsTasks,
  getReportsUsers,
} from '../../api/reports';
import { formatMoney } from '../../utils/money';
import useShowMoney from '../../recoil/hooks/showMoney';

const Home: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const { showMoney } = useShowMoney();

  console.log('showMoney', showMoney);

  const isAdmin = useMemo(() => {
    return authenticate?.user?.role === 'admin';
  }, [authenticate?.user?.role]);

  const navigate = useNavigate();

  const [closing, setClosing] = useState('');

  const [requests, setRequests] = useState({
    count: 0,
    pendingRequests: 0,
    completedRequests: 0,
  });

  const [tasks, setTasks] = useState({
    count: 0,
    todoTasks: 0,
    doneTasks: 0,
    overdueTasks: 0,
    cancelTasks: 0,
  });

  const [users, setUsers] = useState({
    count: 0,
    accessToday: 0,
  });

  const [clients, setClients] = useState({
    count: 0,
    countNewClients: 0,
  });

  const getRequests = useCallback(async () => {
    if (!authenticate?.token) {
      return;
    }
    try {
      const data = await getReportsRequests(authenticate?.token);

      setRequests(data);
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  }, [authenticate?.token]);

  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const getUsers = useCallback(async () => {
    if (!authenticate?.token || !isAdmin) {
      return;
    }
    try {
      const data = await getReportsUsers(authenticate?.token);

      setUsers(data);
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  }, [authenticate?.token, isAdmin]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getClients = useCallback(async () => {
    if (!authenticate?.token || !isAdmin) {
      return;
    }
    try {
      const data = await getReportsClients(authenticate?.token);

      setClients(data);
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  }, [authenticate?.token, isAdmin]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const getClosings = useCallback(async () => {
    if (!authenticate?.token || !isAdmin) {
      return;
    }
    try {
      const data = await getReportsClosings(authenticate?.token);

      setClosing(data?.count);
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  }, [authenticate?.token, isAdmin]);

  useEffect(() => {
    getClosings();
  }, [getClosings]);

  const getTasks = useCallback(async () => {
    if (!authenticate?.token) {
      return;
    }
    try {
      const data = await getReportsTasks(authenticate?.token);

      setTasks(data);
    } catch (erro) {
      // eslint-disable-next-line no-console
      console.log('erro', erro);
    }
  }, [authenticate?.token]);

  useEffect(() => {
    getTasks();
  }, [getTasks]);

  return (
    <StyledRoot>
      <StyledContentCards>
        <StyledContentCard>
          <CardHome
            onClick={() => navigate('/dash/requests')}
            number={`${requests?.count}`}
            icon={<WorkHistoryIcon style={{ width: 30, height: 30 }} />}
            title="Atendimentos"
            description="Neste mês"
            notification={
              requests?.pendingRequests ? { color: '#ff3d00' } : undefined
            }
          />
        </StyledContentCard>

        <StyledContentCard>
          <CardHome
            onClick={() => navigate('/dash/tasks')}
            icon={<BusinessIcon style={{ width: 30, height: 30 }} />}
            number={`${tasks.count}`}
            title="Tarefas"
            description="Neste mês"
            notification={tasks.overdueTasks ? { color: '#ff3d00' } : undefined}
          />
        </StyledContentCard>

        {isAdmin && (
          <React.Fragment>
            <StyledContentCard>
              <CardHome
                onClick={() => navigate('/dash/closing')}
                number={formatMoney(closing)}
                icon={<BusinessIcon style={{ width: 30, height: 30 }} />}
                title="Fechamentos"
                description="Neste mês"
                hiddenValue={!showMoney}
              />
            </StyledContentCard>

            <StyledContentCard>
              <CardHome
                onClick={() => navigate('/dash/clients')}
                icon={<BusinessIcon style={{ width: 30, height: 30 }} />}
                number={`${clients.count}`}
                title="Clientes"
                description={
                  clients.countNewClients > 1
                    ? `${clients.countNewClients} clientes novos nesta semana`
                    : `${clients.countNewClients} cliente novo nesta semana`
                }
              />
            </StyledContentCard>

            <StyledContentCard>
              <CardHome
                onClick={() => navigate('/dash/users')}
                number={`${users.count}`}
                icon={<PeopleAltIcon style={{ width: 30, height: 30 }} />}
                title="Usuários"
                description={`${users.accessToday} acessaram hoje`}
              />
            </StyledContentCard>
          </React.Fragment>
        )}
      </StyledContentCards>
      <StyledContentCards>
        <StyledContentCard>
          <CardGraph
            title="Atendimentos"
            total={requests?.count}
            data={[
              {
                value: requests?.pendingRequests,
                label: 'Em andamento',
                color: '#ff3d00',
              },
              {
                value: requests?.completedRequests,
                label: 'Finalizado',
                color: '#007d09',
              },
            ]}
            notification={
              requests?.pendingRequests ? { color: '#ff3d00' } : undefined
            }
          />
        </StyledContentCard>
        <StyledContentCard>
          <CardGraph
            title="Tarefas"
            data={[
              {
                value: tasks.todoTasks,
                label: 'Pendente',
                color: '#ebebeb',
              },
              {
                value: tasks.overdueTasks,
                label: 'Atrasado',
                color: '#ff3d00',
              },
              {
                value: tasks.doneTasks,
                label: 'Finalizado',
                color: '#38A658',
              },
              // {
              //   value: tasks.cancelTasks,
              //   label: 'Cancelado',
              //   color: '#d32f2f',
              // },
            ]}
            total={tasks?.count}
            notification={tasks.overdueTasks ? { color: '#ff3d00' } : undefined}
          />
        </StyledContentCard>
        {/* <StyledContentGraph>
          <CardGraphLine />
        </StyledContentGraph> */}
      </StyledContentCards>

      <StyledContentRequests>
        <TableTitle
          icon={<WorkHistoryIcon style={{ width: 36, height: 36 }} />}
          title="Últimos atendimentos"
        />

        <RenderRequests limit={3} simpleVision />
      </StyledContentRequests>
    </StyledRoot>
  );
};

export default Home;
