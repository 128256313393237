import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { deleteClient, getClients } from '../../api/client';
import TableTitle from '../../components/Table/TableTitle';
import Table from '../../components/Table';
import { maskCnpjOrCpf } from '../../utils/mask';
import { formatClientType } from './utils';
import Backbutton from '../../components/Backbutton';
import { formatSeconds } from '../../utils/date';
import NotFoundItems from '../../components/NotFoundItems';
import ConfirmModal from '../../components/ConfirmModal';
import SmallLoading from '../../components/SmallLoading';

const Clients: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState('');

  const [clients, setClients] = useState([]);

  const handleOpenDeleteModal = (id: string) => {
    setOpen(true);
    setClientToDelete(id);
  };

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token) {
        const data = await getClients(authenticate.token);

        setClients(data?.clients || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteClient(authenticate.token, id);

        getData();
      }

      toast('Cliente deletado com sucesso!', { type: 'success' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const clientsFiltered = useMemo(() => {
    if (!clients) {
      return [];
    }

    if (!search) {
      return clients;
    }

    return clients.filter((item: any) => {
      return item.name.toUpperCase().includes(search.toUpperCase());
    });
  }, [search, clients]);

  return (
    <React.Fragment>
      <StyledRoot>
        <div style={{ display: 'flex', flex: 1 }}>
          <Backbutton path="/dash" labels={['Clientes']} />

          <TableTitle
            title=""
            search={{
              handleSearch: (value: string) => setSearch(value),
            }}
            button={{
              text: 'Novo cliente',
              onAction: () => navigate('/dash/clients/create'),
            }}
          />
        </div>

        {isLoading && <SmallLoading />}

        {!isLoading && (
          <Table
            header={{
              backgroundColor: '#38A658',
              color: '#fff',
              columns: [
                {
                  label: 'Nome',
                },
                {
                  label: 'CNPJ / CPF',
                },
                {
                  label: 'Tipo do cliente',
                },
                {
                  label: 'Horas do pacote',
                },
                {
                  label: 'Horas utilizadas no mês',
                },
                {
                  label: 'Horas restantes',
                },
              ],
            }}
            body={{
              rows: [
                { key: 'name' },
                { key: 'identification', transform: maskCnpjOrCpf },
                {
                  key: 'type',
                  transform: (value) => formatClientType(value, false),
                },
                {
                  key: 'hours',
                  transform: (value) => (value ? `${value}h` : '-'),
                },
                {
                  key: 'minutesMonthlyConsumed',
                  transform: (value) => formatSeconds(value),
                },
                {
                  key: 'minutesMonthlyConsumed',
                  transformBody: (value) => {
                    if (!value.hours) {
                      return '--';
                    }

                    if (!value.minutesMonthlyConsumed) {
                      return value.hours ? `${value.hours}h` : '-';
                    }

                    const result =
                      value.hours * 60 * 60 - value.minutesMonthlyConsumed;

                    return (
                      <span style={{ color: result < 0 ? 'red' : '' }}>
                        {formatSeconds(result)}
                      </span>
                    );
                  },
                },
              ],
              options: [
                {
                  label: 'Editar',
                  onClick: (item) => navigate(`/dash/clients/${item.id}/edit`),
                },
                {
                  label: 'Deletar',
                  onClick: (item) => handleOpenDeleteModal(item.id),
                },
              ],
              onClick: (value) => navigate(`/dash/clients/${value.id}`),
            }}
            data={clientsFiltered}
          />
        )}

        {!isLoading && !clientsFiltered.length && (
          <NotFoundItems
            label={
              clients.length
                ? 'Nenhum cliente encontrado para essa busca!'
                : undefined
            }
          />
        )}
      </StyledRoot>
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(clientToDelete)}
        title="Deletar cliente"
        message="Deseja confirmar a exclusão do cliente?"
      />
    </React.Fragment>
  );
};

export default Clients;
