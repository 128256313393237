/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import useAuthenticate from '../../recoil/hooks/authenticate';

import Card from '../../components/Card';
import { removeMask } from '../../utils/mask';

import { StyledRoot } from './styles';
import Backbutton from '../../components/Backbutton';
import { editRequest, getRequestDetails } from '../../api/request';
import EditRequestForm from '../../components/EditRequestForm';

const RequestDetailsEdit: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { id } = useParams();

  const [requestDetails, setRequestDetails] = useState<any>({});

  const getData = useCallback(
    async (id: string) => {
      try {
        if (authenticate.token) {
          const data = await getRequestDetails(authenticate.token, id);

          setRequestDetails(data?.data || {});
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [getData, id]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmit = async (values: any) => {
    try {
      if (id) {
        const startDate = prepareDate(values.startDate, values.startDateTime);
        const endDate = prepareDate(values.endDate, values.endDateTime);

        // eslint-disable-next-line no-param-reassign
        delete values.user;
        // eslint-disable-next-line no-param-reassign
        delete values.id;
        // eslint-disable-next-line no-param-reassign
        delete values.task;

        await editRequest(
          {
            ...values,
            food: Number(removeMask(values.food)),
            taxRoad: Number(removeMask(values.taxRoad)),
            parking: Number(removeMask(values.parking)),
            startDate,
            endDate,
            startDateClosing: startDate,
            endDateClosing: endDate,
          },
          authenticate.token,
          id
        );

        navigate(`/dash/requests/${id}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path={`/dash/requests/${id}`}
        labels={['Atendimentos', 'Detalhes do atendimento', 'Atendimento']}
      />

      <Card>
        <EditRequestForm
          isLoading={false}
          onSubmit={handleSubmit}
          requestDetails={requestDetails}
        />
      </Card>
    </StyledRoot>
  );
};

export default RequestDetailsEdit;
