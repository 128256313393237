import * as yup from 'yup';

export enum TypeRequestEnum {
  REMOTO = 'remote',
  PRESENTIAL = 'presential',
}

export enum StatusRequestEnum {
  COMPLETED = 'completed',
  PENDING = 'pending',
}

// const requiredKeys = ['clientId', 'type', 'startDate', 'endDate', 'description', 'status'];

export const initialRequestFormValues = {
  client: '',
  clientName: '',
  type: TypeRequestEnum.REMOTO,
  startDate: null,
  startDateTime: null,
  endDate: null,
  endDateTime: null,
  description: '',
  status: StatusRequestEnum.PENDING,
  singleClient: false,
  observation: '',
  food: '',
  taxRoad: '',
  parking: '',
};

export interface RequestFormProps {
  client: string;
  clientName: string;
  startDate: string | null;
  startDateTime: string | null;
  endDate: string | null;
  endDateTime: string | null;
  description: string;
  type: TypeRequestEnum;
  status: StatusRequestEnum;
  observation: string;
  singleClient: boolean;
  food: string;
  taxRoad: string;
  parking: string;
  task?: string;
}

export const validationSchema = yup.object({
  client: yup.string().when('singleClient', {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required('Campo obrigátorio'),
  }),
  singleClient: yup.boolean(),
  clientName: yup.string().when('singleClient', {
    is: true,
    then: yup.string().required('Campo obrigátorio'),
    otherwise: yup.string(),
  }),
  startDate: yup.date().required('Campo obrigatório').nullable(),
  startDateTime: yup.string().required('Campo obrigatório').nullable(),
  endDate: yup.date().required('Campo obrigatório').nullable(),
  endDateTime: yup.string().required('Campo obrigatório').nullable(),
  description: yup.string().required('Campo obrigatório'),
  observation: yup.string(),
  type: yup
    .mixed<TypeRequestEnum>()
    .oneOf(
      [TypeRequestEnum.REMOTO, TypeRequestEnum.PRESENTIAL],
      'Campo inválido'
    )
    .required('Campo obrigatório'),
  status: yup
    .mixed<StatusRequestEnum>()
    .oneOf(
      [StatusRequestEnum.COMPLETED, StatusRequestEnum.PENDING],
      'Campo inválido'
    )
    .required('Campo obrigatório'),
});
