import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuthenticate from '../../../recoil/hooks/authenticate';

import { deleteRequest, getRequests } from '../../../api/request';
import { StyledRoot } from '../styles';
import Table from '../../../components/Table';
import {
  calculateDiffDatesInSeconds,
  formatOnlyDateWithDayOfWeek,
  formatSeconds,
} from '../../../utils/date';
import { formatClientType } from '../../Clients/utils';
import NotFoundItems from '../../../components/NotFoundItems';
import ConfirmModal from '../../../components/ConfirmModal';
import Loading from '../../../components/Loading';
import SmallLoading from '../../../components/SmallLoading';

interface RenderRequestsProps {
  limit?: number;
  search?: string;
  simpleVision?: boolean;
}

const RenderRequests: React.FC<RenderRequestsProps> = ({
  limit,
  search,
  simpleVision,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const navigate = useNavigate();

  const [requests, setRequests] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState('');

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token) {
        const data = await getRequests(authenticate.token);

        let values = data?.requests || [];

        if (limit) {
          values = values.slice(0, limit);
        }

        setRequests(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token, limit]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleOpenDeleteModal = (id: string) => {
    setOpen(true);
    setDataToDelete(id);
  };

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteRequest(authenticate.token, id);

        getData();
      }

      toast('Atendimento deletado com sucesso!', { type: 'success' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const formatStatusIcon = (status: string): ReactElement => {
    if (status === 'pending') {
      return <Chip label="Em andamento" color="warning" />;
    }

    return <Chip label="Finalizado" color="primary" />;
  };

  const columns = useMemo(() => {
    if (simpleVision) {
      return [
        {
          label: 'Consultor',
        },
        {
          label: 'Cliente',
        },
        {
          label: 'Data',
        },

        {
          label: 'Status',
        },
      ];
    }

    return [
      {
        label: 'Consultor',
      },
      {
        label: 'Cliente',
      },
      {
        label: 'Data',
      },
      {
        label: 'Duração',
      },
      {
        label: 'Status',
      },
    ];
  }, [simpleVision]);

  const rows = useMemo(() => {
    if (simpleVision) {
      return [
        { key: 'user', subkey: 'name' },
        {
          key: 'client',
          transformBody: (item: any) => {
            return `${item.client?.name} ${formatClientType(
              item.client?.type
            )}`;
          },
        },
        { key: 'startDate', transform: formatOnlyDateWithDayOfWeek },

        { key: 'status', transformIcon: formatStatusIcon },
      ];
    }

    return [
      { key: 'user', subkey: 'name' },

      {
        key: 'client',
        transformBody: (item: any) => {
          return `${item.client?.name} ${formatClientType(item.client?.type)}`;
        },
      },
      { key: 'startDate', transform: formatOnlyDateWithDayOfWeek },

      {
        key: 'endDate',
        transformBody: (params: any) => {
          const seconds = calculateDiffDatesInSeconds(
            params.startDate,
            params.endDate
          );
          return formatSeconds(seconds);
        },
      },
      { key: 'status', transformIcon: formatStatusIcon },
    ];
  }, [simpleVision]);

  const dataFiltered = useMemo(() => {
    if (!requests) {
      return [];
    }

    if (!search) {
      return requests;
    }

    return requests.filter((item: any) => {
      const searchUppercase = search.toUpperCase();
      return (
        item.user?.name?.toUpperCase()?.includes(searchUppercase) ||
        item.user?.email?.toUpperCase()?.includes(searchUppercase) ||
        item.client?.name?.toUpperCase()?.includes(searchUppercase) ||
        item.description?.toUpperCase()?.includes(searchUppercase)
      );
    });
  }, [requests, search]);

  return (
    <Fragment>
      <StyledRoot>
        {isLoading && <SmallLoading />}

        {!isLoading && (
          <Table
            header={{
              backgroundColor: '#38A658',
              color: '#fff',
              columns,
            }}
            body={{
              rows,
              options: !simpleVision
                ? [
                    {
                      label: 'Editar',
                      onClick: (item) =>
                        navigate(`/dash/requests/${item.id}/edit`),
                    },
                    {
                      label: 'Deletar',
                      onClick: (item) => handleOpenDeleteModal(item.id),
                    },
                  ]
                : undefined,
              onClick: (item) => navigate(`/dash/requests/${item.id}`),
              showNotification: (item) =>
                item.status === 'pending' ? '#ff3d00' : '',
            }}
            data={dataFiltered}
          />
        )}

        {!isLoading && !dataFiltered.length && (
          <NotFoundItems
            label={
              requests.length
                ? 'Nenhum atendimento encontrado para essa busca!'
                : undefined
            }
          />
        )}
      </StyledRoot>
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(dataToDelete)}
        title="Deletar atendimento"
        message="Deseja confirmar a exclusão do atendimento?"
      />
    </Fragment>
  );
};

export default RenderRequests;
