import yup from '../../utils/yup';

export enum ClientTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  LOOSE = 'loose',
}
export const initialClientFormValues = {
  name: '',
  identification: '',
  fantasyName: '',
  stateRegistration: '',
  municipalRegistration: '',
  startDateService: '',
  startTimeService: '',
  endTimeService: '',

  phoneOne: '',
  contactOne: '',
  phoneTwo: '',
  contactTwo: '',
  emailOne: '',
  emailTwo: '',
  emailSendInvoice: '',
  address: '',
  number: '',
  district: '',
  additionalInformation: '',
  state: '',
  city: '',
  cep: '',
  observation: '',

  type: ClientTypeEnum.MONTHLY,
  contractValue: '',
  hours: '',

  food: '',
  taxRoad: '',
  parking: '',
  accomodation: '',
  visit: '',
  mileage: '',
  valueMileage: '',
  valueHour: '',
  extraValueHour: '',
  weekendValueHour: '',
};

export interface ClientFormProps {
  name: string;
  identification: string;
  fantasyName: string;
  stateRegistration: string;
  municipalRegistration: string;
  startDateService: string;
  startTimeService: string;
  endTimeService: string;

  phoneOne: string;
  contactOne: string;
  phoneTwo: string;
  contactTwo: string;
  emailOne: string;
  emailTwo: string;
  emailSendInvoice: string;
  address: string;
  number: string;
  district: string;
  additionalInformation: string;
  state: string;
  city: string;
  cep: string;
  observation: string;

  type: ClientTypeEnum;
  contractValue: string;
  hours: string;

  food: string;
  taxRoad: string;
  parking: string;
  accomodation: string;
  visit: string;
  mileage: string;
  valueMileage: string;
  valueHour: string;
  extraValueHour: string;
  weekendValueHour: string;
}

export const validationSchema = yup.object({
  name: yup.string().required('Nome obrigatório'),
  identification: yup
    .string()
    .validCpfOrCnpj('Campo inválido')
    .required('Campo obrigatório'),
  fantasyName: yup.string(),
  startDateService: yup.date().nullable(),
  startTimeService: yup.string().nullable(),
  endTimeService: yup.string().nullable(),

  stateRegistration: yup.string(),
  municipalRegistration: yup.string(),

  phoneOne: yup.string().min(15, 'Campo inválido').max(15, 'Campo inválido'),
  contactOne: yup.string(),

  phoneTwo: yup.string().min(15, 'Campo inválido').max(15, 'Campo inválido'),
  emailOne: yup.string().email('E-mail inválido'),
  emailTwo: yup.string().email('E-mail inválido'),
  emailSendInvoice: yup.string().email('E-mail inválido'),
  address: yup.string(),
  number: yup.string(),
  district: yup.string(),
  additionalInformation: yup.string(),
  state: yup.string(),
  city: yup.string(),
  cep: yup.string().max(9, 'Campo inválido').min(9, 'Campo inválido'),
  observation: yup.string(),

  type: yup.string().required('Campo obrigatório'),
  contractValue: yup.string(),
});
