import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#38A658',
    },
  },
  typography: {
    fontFamily: ['Lato'].join(','),
    button: {
      fontFamily: ['Lato'].join(','),
    },
    allVariants: {
      fontFamily: ['Lato'].join(','),
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          // '& .MuiInputBase-root.Mui-disabled': {
          //   color: 'red !important', // (default alpha is 0.38)
          // },
        },
      },
    },
  },
});
