import styled from 'styled-components';

export const StyledRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',

  padding: 24,
  boxSizing: 'border-box',
});

export const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
});

export const StyledContentInputs = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
});

export const StyledContentButtons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'end',
  gap: 16,
  width: '100%',
});

export const StyledContentTextFields = styled('div')<{ flex?: number }>(
  ({ flex = 1 }) => ({
    display: 'flex',
    gap: 16,
    flex,
  })
);
