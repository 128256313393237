/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Table from '../../components/Table';
import TableTitle from '../../components/Table/TableTitle';
import { getClosings } from '../../api/closing';
import { formatMonth } from '../../utils/month';
import { formatMoney } from '../../utils/money';
import { formatClientType } from '../Clients/utils';
import useShowMoney from '../../recoil/hooks/showMoney';
import Backbutton from '../../components/Backbutton';
import NotFoundItems from '../../components/NotFoundItems';
import SmallLoading from '../../components/SmallLoading';

const Closings: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const [isLoading, setLoading] = useState(true);

  const { showMoney, setShowMoney } = useShowMoney();

  const [closings, setClosings] = useState([]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token) {
        const data = await getClosings(authenticate.token);

        setClosings(data?.data || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const formatClosingStatus = (item: any): string => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (month === item.month && year === item.year) {
      return 'Em aberto';
    }

    if (item.status === 'pending') {
      return 'Em aprovação';
    }

    return 'Fechado';
  };

  const showTotalValue = (value: any) => {
    if (!showMoney) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 10,
              width: 75,
              borderRadius: 4,
              background: '#ccc',
              alignItems: 'center',
            }}
          ></div>
        </div>
      );
    }
    return formatMoney(value);
  };

  const dataFiltered = useMemo(() => {
    if (!closings) {
      return [];
    }

    if (!search) {
      return closings;
    }

    const searchUppercase = search.toUpperCase();

    return closings.filter((item: any) => {
      return item.client?.name?.toUpperCase()?.includes(searchUppercase);
    });
  }, [search, closings]);

  return (
    <StyledRoot>
      <div style={{ display: 'flex', flex: 1 }}>
        <Backbutton path="/dash" labels={['Fechamentos']} />
        <TableTitle
          title=" "
          search={{
            handleSearch: (value: string) => setSearch(value),
          }}
          actions={[
            {
              onAction: () => {
                setShowMoney(!showMoney);
              },
              icon: showMoney ? (
                <VisibilityOffIcon
                  style={{ width: 36, height: 36 }}
                  color="primary"
                />
              ) : (
                <VisibilityIcon
                  style={{ width: 36, height: 36 }}
                  color="primary"
                />
              ),
            },
          ]}
        />
      </div>

      {isLoading && <SmallLoading />}

      {!isLoading && (
        <Table
          header={{
            backgroundColor: '#38A658',
            color: '#fff',
            columns: [
              {
                label: 'Cliente',
              },
              {
                label: 'Mês',
              },
              {
                label: 'Ano',
              },
              {
                label: 'Atendimentos',
              },
              {
                label: 'Total',
              },
              {
                label: 'Status',
              },
            ],
          }}
          body={{
            rows: [
              {
                key: 'client',
                subkey: 'name',
                transformBody: (item: any) => {
                  return `${item.client?.name} ${formatClientType(
                    item.client?.type
                  )}`;
                },
              },
              { key: 'month', transform: formatMonth },
              { key: 'year' },
              { key: 'requests', subkey: 'length' },
              { key: 'total', transformIcon: showTotalValue },
              { key: 'status', transformBody: formatClosingStatus },
            ],
            onClick: (data) => navigate(`/dash/closing/${data._id}`),
          }}
          data={dataFiltered}
        />
      )}

      {!isLoading && !dataFiltered.length && (
        <NotFoundItems
          label={
            closings.length
              ? 'Nenhum cliente encontrada para essa busca!'
              : undefined
          }
        />
      )}
    </StyledRoot>
  );
};

export default Closings;
