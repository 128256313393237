/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { StyledContainer, StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';

import {
  calculateDiffDatesInSeconds,
  formatDate,
  formatSeconds,
} from '../../utils/date';
import { formatMoney } from '../../utils/money';
import Card from '../../components/Card';
import DetailsInfo from '../../components/DetailsInfo';
import { maskCnpjOrCpf } from '../../utils/mask';
import { isCpfValid } from '../../utils/cpf';
import { getClosingRequestDetails } from '../../api/closing';
import { formatClientType } from '../Clients/utils';
import Backbutton from '../../components/Backbutton';

const ClosingRequestDetails: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { closingId, requestId } = useParams();

  const [requestDetails, setRequestDetails] = useState<any>({});

  const getData = useCallback(
    async (closingId: string, requestId: string) => {
      try {
        if (authenticate.token) {
          const data = await getClosingRequestDetails(
            authenticate.token,
            closingId,
            requestId
          );

          setRequestDetails(data?.data || {});
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (closingId && requestId) {
      getData(closingId, requestId);
    }
  }, [getData, closingId, requestId]);

  const formatStatus = (status: string): string => {
    if (status === 'pending') {
      return 'Pendente';
    }

    return 'Finalizado';
  };

  const formatType = (status: string): string => {
    if (status === 'remote') {
      return 'Remoto';
    }

    return 'Presencial';
  };

  const handleEdit = () => {
    // warn: dont need "/", because redirect actual url + edit
    navigate('edit');
  };

  return (
    <StyledRoot>
      <Backbutton
        path={`/dash/closing/${closingId}`}
        labels={['Fechamentos', 'Detalhes do fechamento', 'Atendimento']}
      />

      <Card>
        <StyledContainer>
          <DetailsInfo
            isLoading={false}
            sections={[
              {
                title: 'Cliente',
                rows: [
                  {
                    label: 'Razão social',
                    key: 'name',
                  },
                  {
                    label:
                      requestDetails?.client?.identification &&
                      isCpfValid(requestDetails?.client?.identification)
                        ? 'CPF'
                        : 'CNPJ',
                    key: 'identification',
                    transform: maskCnpjOrCpf,
                  },
                  {
                    label: 'Tipo',
                    key: 'type',
                    transform: formatClientType,
                  },
                ],
                data: requestDetails?.client,
              },
              {
                title: 'Consultor',
                rows: [
                  {
                    label: 'Nome',
                    key: 'name',
                  },
                  {
                    label: 'E-mail',
                    key: 'email',
                  },
                ],
                data: requestDetails?.user,
              },

              {
                title: 'Atendimento',
                rows: [
                  {
                    label: 'Data de início',
                    key: 'startDateClosing',
                    transform: formatDate,
                  },
                  {
                    label: 'Data de fim',
                    key: 'endDateClosing',
                    transform: formatDate,
                  },
                  {
                    label: 'Duração',
                    key: 'endDateClosing',
                    transformBody: (params: any) => {
                      const seconds = calculateDiffDatesInSeconds(
                        params.startDateClosing,
                        params.endDateClosing
                      );
                      return formatSeconds(seconds);
                    },
                  },
                  {
                    label: 'Tipo do atendimento',
                    key: 'type',
                    transform: formatType,
                  },
                  {
                    label: 'Status',
                    key: 'status',
                    transform: formatStatus,
                  },
                  {
                    label: 'Descrição',
                    key: 'description',
                    isHtml: true,
                  },
                  {
                    label: 'Observação (interna)',
                    key: 'observation',
                    isHtml: true,
                  },
                ],
                data: requestDetails,
              },
              {
                title: 'Cobrança',
                rows: [
                  {
                    label: 'Cobrar atendimentos',
                    key: 'chargeRequest',
                    transform: (charge) => (charge ? 'Sim' : 'Não'),
                  },
                  {
                    label: 'Total',
                    key: 'total',
                    transform: formatMoney,
                  },
                  {
                    label: 'Duração',
                    key: 'duration',
                    transformBody: (params: any) => {
                      const seconds = calculateDiffDatesInSeconds(
                        params.startDateClosing,
                        params.endDateClosing
                      );
                      return formatSeconds(seconds);
                    },
                  },
                  {
                    label: 'Valor da hora',
                    key: 'valuePerHour',
                    transform: formatMoney,
                  },
                  {
                    label: 'Valor da hora extra',
                    key: 'valuePerExtraHour',
                    transform: formatMoney,
                  },
                  {
                    label: 'Valor da hora no final de semana',
                    key: 'weekendValueHour',
                    transform: formatMoney,
                  },
                  {
                    label: 'Alimentação',
                    key: 'food',
                    transform: formatMoney,
                  },
                  {
                    label: 'Pedágio',
                    key: 'taxRoad',
                    transform: formatMoney,
                  },
                  {
                    label: 'Estacionamento',
                    key: 'parking',
                    transform: formatMoney,
                  },
                  {
                    label: 'Hospedagem',
                    key: 'accomodation',
                    transform: formatMoney,
                  },
                  {
                    label: 'Visita',
                    key: 'visit',
                    transform: formatMoney,
                  },
                  {
                    label: 'Quilômetros',
                    key: 'mileage',
                  },
                  {
                    label: 'Valor por cada quilômetro',
                    key: 'valueMileage',
                    transform: formatMoney,
                  },
                  {
                    label: 'Valor total dos quilômetros',
                    key: 'valueMileage',
                    transformBody: (value) => {
                      const total = value.mileage * value.valueMileage;
                      return formatMoney(total);
                    },
                  },
                ],
                data: requestDetails,
              },
            ]}
          />
          <div>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={false}
              style={{
                fontWeight: 'bold',
                letterSpacing: 1,
                fontSize: 16,
                height: 50,
              }}
              onClick={handleEdit}
            >
              Editar
            </Button>
          </div>
        </StyledContainer>
      </Card>
    </StyledRoot>
  );
};

export default ClosingRequestDetails;
