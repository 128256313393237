import React, { ReactElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledContainer, StyledRoot, StyledText } from './styles';
import Card from '../Card';

const SmallLoading: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      <Card>
        <StyledContainer>
          <CircularProgress size={64} />
          <StyledText>Carregando</StyledText>
        </StyledContainer>
      </Card>
    </StyledRoot>
  );
};

export default SmallLoading;
