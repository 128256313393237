import styled from 'styled-components';

export const StyledRoot = styled('div')({
  boxSizing: 'border-box',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
});
