import api from '../services/api';

export const getUsers = async (token: string): Promise<any> => {
  const result = await api.get('/api/users', {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const getUser = async (token: string, id: string): Promise<any> => {
  const result = await api.get(`/api/users/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

interface AddUserRequest {
  email: string;
  name: string;
  role: string;
  password: string;
}

export const addUser = async (
  data: AddUserRequest,
  token: string
): Promise<void> => {
  await api.post('/api/users', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const deleteUser = async (token: string, id: string): Promise<void> => {
  await api.delete(`/api/users/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

interface EditUserRequest {
  email: string;
  name: string;
  role: string;
}

export const editUser = async (
  id: string,
  data: EditUserRequest,
  token: string
): Promise<void> => {
  await api.patch(`/api/users/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

interface EditUserPasswordRequest {
  password: string;
}

export const editUserPassword = async (
  id: string,
  data: EditUserPasswordRequest,
  token: string
): Promise<void> => {
  await api.patch(`/api/users/${id}/password`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getUsers,
  getUser,
  addUser,
  editUserPassword,
  editUser,
  deleteUser,
};
