/* eslint-disable no-underscore-dangle */
import React, { Fragment, ReactElement } from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';

import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import DetailsInfo, {
  DetailsSectionProps,
} from '../../../components/DetailsInfo';
import { isCpfValid } from '../../../utils/cpf';
import { maskCnpjOrCpf } from '../../../utils/mask';
import { formatClientType } from '../../Clients/utils';
import {
  calculateDiffDatesInSeconds,
  formatDate,
  formatSeconds,
} from '../../../utils/date';
import { formatRepeatOften } from '../utils';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { StyledEditRequestContainer, StyledEditRequestItem } from './styles';

interface Props {
  data: any;
  handleOpenDeleteModal?: () => void;
  handleEdit: () => void;
  handleRedirectEdit?: () => void;
}

const TaskDetails: React.FC<Props> = ({
  data,
  handleEdit,
  handleOpenDeleteModal,
  handleRedirectEdit,
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  const formatStatus = (status: string): string => {
    if (status === 'pending') {
      return 'Pendente';
    }

    return 'Finalizado';
  };

  const formatType = (status: string): string => {
    if (status === 'remote') {
      return 'Remoto';
    }

    return 'Presencial';
  };

  const handleButtonEdit = (event: any) => {
    event.stopPropagation();

    handleEdit();
  };

  const handleEventAbort = (event: any) => {
    event.stopPropagation();
  };

  return (
    <div
      style={{ width: '100%', position: 'relative' }}
      onClick={handleEventAbort}
    >
      <StyledEditRequestContainer>
        <StyledEditRequestItem onClick={() => handleOpenDeleteModal?.()}>
          <DeleteIcon fontSize="large" color="error" />
          <div>Deletar tarefa </div>
        </StyledEditRequestItem>
        {['todo', 'overdue'].includes(data.status) && (
          <Fragment>
            <StyledEditRequestItem onClick={() => handleRedirectEdit?.()}>
              <EditNoteIcon fontSize="large" color="info" />
              <div>Editar tarefa </div>
            </StyledEditRequestItem>
            <StyledEditRequestItem onClick={handleButtonEdit}>
              <PlaylistAddCheckOutlinedIcon fontSize="large" color="primary" />
              <div>Concluir tarefa </div>
            </StyledEditRequestItem>
          </Fragment>
        )}
      </StyledEditRequestContainer>

      <DetailsInfo
        isLoading={false}
        sections={
          [
            { title: 'Consultor' },
            ...data.user?.map((item: any) => {
              return {
                rows: [
                  {
                    label: 'Nome',
                    key: 'name',
                  },
                  {
                    label: 'E-mail',
                    key: 'email',
                  },
                ],
                data: item,
              };
            }),

            data?.client
              ? {
                  title: 'Cliente',
                  rows: [
                    {
                      label: 'Razão social',
                      key: 'name',
                    },
                    authenticate.user.role === 'admin'
                      ? {
                          label:
                            data?.client?.identification &&
                            isCpfValid(data?.client?.identification)
                              ? 'CPF'
                              : 'CNPJ',
                          key: 'identification',
                          transform: maskCnpjOrCpf,
                        }
                      : undefined,
                    authenticate.user.role === 'admin'
                      ? {
                          label: 'Tipo',
                          key: 'type',
                          transform: formatClientType,
                        }
                      : undefined,
                  ].filter((item) => !!item),
                  data: data?.client,
                }
              : undefined,
            {
              title: 'Tarefa',
              rows: [
                {
                  label: 'Tarefa',
                  key: 'internal',
                  transform: (value: boolean) =>
                    value ? 'Interna' : 'Cliente',
                },
                {
                  label: 'Data',
                  key: 'startDate',
                  transform: formatDate,
                },
                {
                  label: 'Tipo do atendimento',
                  key: 'type',
                  transform: formatType,
                },
                {
                  label: 'Recorrência',
                  key: 'repeatOften',
                  transform: formatRepeatOften,
                },
                {
                  label: 'Descrição',
                  key: 'description',
                  isHtml: true,
                },
              ],
              data,
            },

            data.request
              ? {
                  title: 'Atendimento',
                  rows: [
                    {
                      label: 'Data de início',
                      key: 'startDate',
                      transform: formatDate,
                    },
                    {
                      label: 'Data de fim',
                      key: 'endDate',
                      transform: formatDate,
                    },
                    {
                      label: 'Duração',
                      key: 'endDate',
                      transformBody: (params: any) => {
                        const seconds = calculateDiffDatesInSeconds(
                          params.startDate,
                          params.endDate
                        );
                        return formatSeconds(seconds);
                      },
                    },
                    {
                      label: 'Tipo do atendimento',
                      key: 'type',
                      transform: formatType,
                    },
                    {
                      label: 'Status',
                      key: 'status',
                      transform: formatStatus,
                    },
                    {
                      label: 'Descrição do serviço',
                      key: 'description',
                      isHtml: true,
                    },
                    {
                      label: 'Observação (interna)',
                      key: 'observation',
                      isHtml: true,
                    },
                  ],
                  data: data.request,
                }
              : undefined,

            data.comment?.length
              ? {
                  title: 'Resolução',
                  data: data.comment[0],
                  rows: [
                    {
                      label: 'Data de início',
                      key: 'startDate',
                      transform: formatDate,
                    },
                    {
                      label: 'Data de fim',
                      key: 'endDate',
                      transform: formatDate,
                    },
                    {
                      label: 'Resolução',
                      key: 'description',
                      isHtml: true,
                    },
                  ],
                }
              : undefined,
          ].filter((item) => !!item) as DetailsSectionProps[]
        }
      />
    </div>
  );
};

export default TaskDetails;
