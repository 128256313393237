import React, { ReactElement, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';

import TableTitle from '../../components/Table/TableTitle';
import RenderRequests from './RenderRequests';
import Backbutton from '../../components/Backbutton';

const Requests: React.FC = (): ReactElement => {
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  return (
    <StyledRoot>
      <div style={{ display: 'flex', flex: 1 }}>
        <Backbutton path="/dash" labels={['Atendimentos']} />
        <TableTitle
          title=""
          search={{
            handleSearch: (value) => setSearch(value),
          }}
          button={{
            text: 'Novo atendimento',
            onAction: () => navigate('/dash/requests/create'),
          }}
        />
      </div>

      <RenderRequests search={search} />
    </StyledRoot>
  );
};

export default Requests;
