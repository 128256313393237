import React, { ReactElement } from 'react';

interface CardProps {
  children?: ReactElement | ReactElement[];
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({ children, onClick }): ReactElement => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={() => handleClick()}
      style={{
        padding: 0,
        // border: '1px solid #ccc',
        // boxShadow: '0 0 13px 0px rgba(0, 0, 0, 0.25)',
        marginBottom: 8,
        background: '#fff',
        display: 'flex',
        width: '100%',
        borderRadius: 8,
        cursor: onClick ? 'pointer' : undefined,
        boxSizing: 'border-box',
        // border: '1px solid #ccc',
        // boxShadow: '0 0 13px 0px rgba(0, 0, 0, 0.25)',
        boxShadow: 'rgba(0, 0, 0, 0.25) 5px -3px 7px 0px',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
};

export default Card;
