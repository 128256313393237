import React, { ReactElement } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Card from '../../components/Card';
import Backbutton from '../../components/Backbutton';
import { editUserPassword } from '../../api/users';
import EditUserPasswordForm from '../../components/EditUserPasswordForm';

const EditUserPassword: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { id } = useParams();

  const handleSubmitEditUser = async (values: any) => {
    try {
      if (id && authenticate.token) {
        await editUserPassword(id, values, authenticate.token);

        toast('Senha alterada com sucesso!', { type: 'success' });
        navigate('/dash/users');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/users" labels={['Usuários', 'Editar senha']} />

      <Card>
        <EditUserPasswordForm
          isLoading={false}
          onSubmit={handleSubmitEditUser}
        />
      </Card>
    </StyledRoot>
  );
};

export default EditUserPassword;
