import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Table from '../../components/Table';
import TableTitle from '../../components/Table/TableTitle';
import Backbutton from '../../components/Backbutton';
import { getHubClients } from '../../api/hub';
import NotFoundItems from '../../components/NotFoundItems';
import SmallLoading from '../../components/SmallLoading';

const Hub: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const [search, setSearch] = useState('');

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      if (authenticate.token) {
        const data = await getHubClients(authenticate.token);

        setClients(data?.clients || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const dataFiltered = useMemo(() => {
    if (!clients) {
      return [];
    }

    if (!search) {
      return clients;
    }

    const searchUppercase = search.toUpperCase();

    return clients.filter((item: any) => {
      return item.name?.toUpperCase()?.includes(searchUppercase);
    });
  }, [search, clients]);

  return (
    <StyledRoot>
      <div style={{ display: 'flex', flex: 1 }}>
        <Backbutton path="/dash" labels={['Base de conhecimento']} />

        <TableTitle
          title=""
          search={{
            handleSearch: (value: string) => setSearch(value),
          }}
          button={{
            text: 'Novo registro',
            onAction: () => navigate('/dash/hub/create'),
          }}
        />
      </div>

      {isLoading && <SmallLoading />}

      {!isLoading && (
        <Table
          header={{
            backgroundColor: '#38A658',
            color: '#fff',
            columns: [
              {
                label: 'Cliente',
              },
              {
                label: 'Registros',
              },
            ],
          }}
          body={{
            rows: [{ key: 'name' }, { key: 'totalHub' }],
            onClick: (data) => navigate(`/dash/hub/clients/${data.id}`),
          }}
          data={dataFiltered}
        />
      )}

      {!isLoading && !dataFiltered.length && (
        <NotFoundItems
          label={
            clients.length
              ? 'Nenhum cliente encontrada para essa busca!'
              : undefined
          }
        />
      )}
    </StyledRoot>
  );
};

export default Hub;
