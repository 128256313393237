import { RequestFormProps } from '../components/AddRequestForm/schema';
import api from '../services/api';

export const getRequests = async (token: string): Promise<any> => {
  const result = await api.get(
    '/api/requests',

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const addRequest = async (
  data: RequestFormProps,
  token: string
): Promise<void> => {
  await api.post('/api/requests', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const deleteRequest = async (
  token: string,
  id: string
): Promise<void> => {
  await api.delete(`/api/requests/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const getRequestDetails = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.get(`/api/requests/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const editRequest = async (
  data: RequestFormProps,
  token: string,
  requestId: string
): Promise<void> => {
  await api.patch(`/api/requests/${requestId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getRequests,
  addRequest,
  getRequestDetails,
  editRequest,
  deleteRequest,
};
