import { getRecoil } from 'recoil-nexus';

import { ClientTypeEnum } from '../../components/AddClientForm/schema';
import authenticateAtom from '../../recoil/atoms/authenticate';

export const formatClientType = (
  type: ClientTypeEnum,
  parentheses = true
): string => {
  const options = {
    [ClientTypeEnum.LOOSE]: 'Avulso',
    [ClientTypeEnum.MONTHLY]: 'Mensal',
    [ClientTypeEnum.YEARLY]: 'Anual',
  };

  if (type === ClientTypeEnum.LOOSE) {
    return parentheses ? '(Avulso)' : 'Avulso';
  }

  const authenticate = getRecoil(authenticateAtom);

  if (authenticate?.user?.role === 'admin') {
    return parentheses ? `(${options[type]})` : options[type];
  }

  return '';
};
