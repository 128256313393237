import React from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, MenuItem, TextField } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  LoginFormProps,
  initialLoginFormValues,
  validationSchema,
} from './schema';

interface Props {
  isLoading: boolean;
  onSubmit: (values: LoginFormProps) => void;
}

const EditUserPasswordForm: React.FC<Props> = ({
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialLoginFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <TextField
            fullWidth
            id="password-user"
            variant="outlined"
            name="password"
            label="Nova senha"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/users')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default EditUserPasswordForm;
