import api from '../services/api';

export const getTasks = async (token: string): Promise<any> => {
  const result = await api.get(
    '/api/tasks',

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const deleteTask = async (token: string, id: string): Promise<void> => {
  await api.delete(`/api/tasks/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

interface EditTaskRequest {
  user: string;
  startDate: Date;
  type: string;
  description: string;
}

export const editTask = async (
  id: string,
  data: EditTaskRequest,
  token: string
): Promise<void> => {
  await api.patch(`/api/tasks/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const getTask = async (taskId: string, token: string): Promise<any> => {
  const result = await api.get(
    `/api/tasks/${taskId}`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface AddTaskRequest {
  client: string;
  user: string;
  startDate: string;
  type: string;
  description: string;
}

export const addTask = async (
  data: AddTaskRequest,
  token: string
): Promise<void> => {
  await api.post('/api/tasks', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export interface FinishTaskInternalRequest {
  endDate: string;
  startDate: string;
  description: string;
  task: string;
}

export const finishInternalTask = async (
  data: FinishTaskInternalRequest,
  token: string
): Promise<void> => {
  await api.post('/api/tasks/finish', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getTasks,
  addTask,
};
