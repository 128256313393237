import * as yup from 'yup';

export enum UserRoleEnum {
  ADMIN = 'admin',
  USER = 'user',
}

export const initialLoginFormValues = {
  name: '',
  email: '',
  // password: '',
  role: UserRoleEnum.USER,
};

export interface LoginFormProps {
  email: string;
  // password: string;
  name: string;
  role: UserRoleEnum;
}

export const validationSchema = yup.object({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  // password: yup
  //   .string()
  //   .min(8, 'Senha deve ter no mínimo 8 caracteres')
  //   .required('Senha obrigatório'),
  role: yup
    .mixed<UserRoleEnum>()
    .oneOf([UserRoleEnum.ADMIN, UserRoleEnum.USER], 'Campo inválido')
    .required('Campo obrigatório'),
});
