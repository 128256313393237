import React, { ReactElement, useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/AddCircle';
import { TextField } from '@mui/material';

import SearchRounded from '@mui/icons-material/SearchRounded';
import ClearIcon from '@mui/icons-material/Clear';
import { useDebounce } from '../../../hooks/useDebounce';

interface TableTitleProps {
  icon?: ReactElement;
  title: string;
  search?: {
    handleSearch: (value: string) => void;
  };
  button?: {
    text: string;
    icon?: ReactElement;
    onAction: () => void;
  };
  secondaryButton?: {
    text: string;
    icon?: ReactElement;
    onAction: () => void;
  };
  actions?: {
    icon?: ReactElement;
    onAction: () => void;
  }[];
}

const TableTitle: React.FC<TableTitleProps> = ({
  icon,
  button,
  search,
  secondaryButton,
  title,
  actions,
}): ReactElement => {
  const searchRef = useRef<HTMLInputElement>();
  const [focused, setFocused] = React.useState(false);

  const [searchValue, setSearchValeu] = useState('');

  const valueDebounce = useDebounce(searchValue, 300);

  useEffect(() => {
    if (search?.handleSearch) {
      search.handleSearch(valueDebounce);
    }
  }, [search, valueDebounce]);

  return (
    <div
      style={{
        padding: '16px 0px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        width: '100%',
      }}
    >
      <div
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 16,
        }}
      >
        {icon}

        <div
          style={{
            fontSize: 28,
            boxSizing: 'border-box',
            color: '#3D4043',
          }}
        >
          {title}
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          gap: 16,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {actions?.map((item, index) => (
          <div
            key={index}
            onClick={item.onAction}
            style={{ cursor: 'pointer' }}
          >
            {item.icon}
          </div>
        ))}

        {search && (
          <div>
            <TextField
              fullWidth
              inputRef={searchRef}
              id="search"
              variant="standard"
              size="small"
              name="search"
              // label="Pesquisa"
              // style={{ background: '#fff', border: '1px solid #1b5e20' }}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                },
                shrink: true,
              }}
              InputProps={{
                endAdornment: searchValue ? (
                  <ClearIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSearchValeu('');
                      if (searchRef.current) {
                        searchRef.current.focus();
                      }
                    }}
                  />
                ) : (
                  <SearchRounded
                    onClick={() => {
                      if (searchRef.current) {
                        searchRef.current.focus();
                      }
                    }}
                  />
                ),
                disableUnderline: true,
                style: {
                  background: '#fff',
                  // height: 38,
                  borderRadius: 8,
                  padding: 4,
                  border: '1px solid #1b5e20',
                  borderWidth: focused ? 2 : 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
              value={searchValue}
              onChange={(e) => setSearchValeu(e.target.value)}
              onBlur={() => setFocused(false)}
              onFocus={() => setFocused(true)}
              // onBlur={formik.handleBlur}
              // error={formik.touched.password && Boolean(formik.errors.password)}
              // helperText={formik.touched.password && formik.errors.password}
            />
          </div>
        )}

        {button && (
          <div
            style={{
              display: 'flex',
              fontSize: 18,
              boxSizing: 'border-box',
              color: '#1b5e20',
              fontWeight: 'bold',
              border: '1px solid #1b5e20',
              padding: '6px 20px',
              borderRadius: 8,
              gap: 16,
              cursor: 'pointer',
              background: '#fff',
            }}
            onClick={button.onAction}
          >
            {button.icon || <AddIcon htmlColor="#1b5e20" />}
            {button.text}
          </div>
        )}
        {secondaryButton && (
          <div
            style={{
              display: 'flex',
              fontSize: 18,
              boxSizing: 'border-box',
              color: '#1b5e20',
              fontWeight: 'bold',
              border: '1px solid #1b5e20',
              padding: '6px 20px',
              borderRadius: 8,
              gap: 16,
              cursor: 'pointer',
              background: '#fff',
            }}
            onClick={secondaryButton.onAction}
          >
            {secondaryButton.icon || <AddIcon htmlColor="#1b5e20" />}
            {secondaryButton.text}
          </div>
        )}
      </div>
    </div>
  );
};

export default TableTitle;
