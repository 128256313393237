import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Card from '../../components/Card';
import { editTask, getTask } from '../../api/task';
import { getUsers } from '../../api/users';
import Backbutton from '../../components/Backbutton';
import EditTaskForm from '../../components/EditTaskForm';

const EditTask: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { id } = useParams();

  const [task, setTask] = useState<any>(null);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && id) {
        const data = await getTask(id, authenticate.token);

        setTask(data?.task);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const [users, setUsers] = useState([]);

  const getUsersData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getUsers(authenticate.token);

        setUsers(data?.users || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitAddTask = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDate, values.startDateTime);

      if (id && authenticate.token) {
        await editTask(
          id,
          {
            user: values.user,
            startDate,
            type: values.type,
            description: values.description,
          },
          authenticate.token
        );

        navigate('/dash/tasks');

        toast('Tarefa editada com sucesso!', { type: 'success' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/tasks" labels={['Tarefas', 'Editar tarefa']} />

      <Card>
        <EditTaskForm
          isLoading={false}
          onSubmit={handleSubmitAddTask}
          users={users}
          task={task}
        />
      </Card>
    </StyledRoot>
  );
};

export default EditTask;
