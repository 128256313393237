import React, { ReactElement } from 'react';
import { StyledRoot } from './styles';

interface Props {
  header?: boolean;
}

const NotFound: React.FC<Props> = ({ header = false }): ReactElement => {
  return (
    <StyledRoot header={header}>
      <div>Página não encontrada</div>
    </StyledRoot>
  );
};

export default NotFound;
