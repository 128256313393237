import React, { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import { addUser } from '../../api/users';
import useAuthenticate from '../../recoil/hooks/authenticate';
import AddUserForm from '../../components/AddUserForm';
import Card from '../../components/Card';
import Backbutton from '../../components/Backbutton';

const AddUser: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const handleSubmitAddUser = async (values: any) => {
    try {
      await addUser(values, authenticate.token);

      navigate('/dash/users');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/users" labels={['Usuários', 'Novo usuário']} />

      <Card>
        <AddUserForm isLoading={false} onSubmit={handleSubmitAddUser} />
      </Card>
    </StyledRoot>
  );
};

export default AddUser;
