/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  RequestFormProps,
  StatusRequestEnum,
  TypeRequestEnum,
  initialRequestFormValues,
  validationSchema,
} from './schema';
import { formatClientType } from '../../pages/Clients/utils';
import { moneyMask } from '../../utils/money';
import { removeMask } from '../../utils/mask';
import { calculateDiffDatesInSeconds } from '../../utils/date';

interface Props {
  isLoading: boolean;
  requestDetails: any;
  onSubmit: (values: RequestFormProps) => void;
}

const EditClosingRequestForm: React.FC<Props> = ({
  isLoading,
  requestDetails,
  onSubmit,
}): React.ReactElement => {
  const navigate = useNavigate();

  const prepareTime = (date: Date): string => {
    const startTime = new Date(date);

    const hours =
      startTime.getHours() >= 10
        ? startTime.getHours()
        : `0${startTime.getHours()}`;

    const minutes =
      startTime.getMinutes() >= 10
        ? startTime.getMinutes()
        : `0${startTime.getMinutes()}`;

    return `${hours}:${minutes}`;
  };

  const prepareOnlyDate = (date: Date): string => {
    const dateParse = new Date(date);

    const day =
      dateParse.getDate() >= 10
        ? dateParse.getDate()
        : `0${dateParse.getDate()}`;

    const month =
      dateParse.getMonth() + 1 >= 10
        ? dateParse.getMonth() + 1
        : `0${dateParse.getMonth() + 1}`;

    const year =
      dateParse.getFullYear() >= 10
        ? dateParse.getFullYear()
        : `0${dateParse.getFullYear()}`;

    return `${year}-${month}-${day}`;
  };

  const initialValues = useMemo(() => {
    if (requestDetails) {
      const startDateTime = prepareTime(requestDetails.startDateClosing);
      const endDateTime = prepareTime(requestDetails.endDateClosing);

      return {
        ...requestDetails,
        client: requestDetails.client?._id,
        startDateClosing: prepareOnlyDate(requestDetails.startDateClosing),
        startDateTime,
        endDateClosing: prepareOnlyDate(requestDetails.endDateClosing),
        endDateTime,
        type: requestDetails.type,
        duration: requestDetails.duration / 60,
        valueHour: moneyMask(requestDetails.valuePerHour),
        extraValueHour: moneyMask(requestDetails.valuePerExtraHour),

        food: moneyMask(requestDetails.food),
        taxRoad: moneyMask(requestDetails.taxRoad),
        parking: moneyMask(requestDetails.parking),
        accomodation: moneyMask(requestDetails.accomodation),
        visit: moneyMask(requestDetails.visit),
        mileage: requestDetails.mileage,
        valueMileage: moneyMask(requestDetails.valueMileage),
        weekendValueHour: moneyMask(requestDetails.weekendValueHour),
        chargeRequest: requestDetails.chargeRequest,
      };
    }

    return initialRequestFormValues;
  }, [requestDetails]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const handleChangeClient = (event: any, value: any) => {
    formik.setFieldValue('client', value.value);
  };

  const handleChangeMoney =
    (key: string) =>
    (event: any): void => {
      const value = moneyMask(event.target.value);

      formik.setFieldValue(key, value);
    };

  const prepareNumber = (n: number | string): number => {
    return n ? Number(n) : 0;
  };

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const duration = useMemo(() => {
    if (
      !formik?.values?.startDateClosing ||
      !formik?.values?.endDateClosing ||
      !formik?.values?.endDateTime ||
      !formik?.values?.startDateTime
    ) {
      return undefined;
    }

    const startDate = prepareDate(
      formik.values.startDateClosing,
      formik.values.startDateTime
    );

    const endDate = prepareDate(
      formik.values.endDateClosing,
      formik.values.endDateTime
    );

    const seconds = calculateDiffDatesInSeconds(
      startDate.toString(),
      endDate.toString()
    );

    const total = seconds / 60;

    return total;
  }, [formik?.values]);

  const calcTotal = (values: any, duration = 0): string => {
    if (
      !formik?.values?.startDateClosing ||
      !formik?.values?.endDateClosing ||
      !formik?.values?.endDateTime ||
      !formik?.values?.startDateTime
    ) {
      return '';
    }
    const totalKilometers =
      prepareNumber(removeMask(values.mileage)) *
      prepareNumber(removeMask(values.valueMileage));

    // const { extraMinutes, normalMinutes, weekendMinutes } =
    //   calculateMinutesRequest(startDate, endDate);

    const totalValueHour =
      prepareNumber(removeMask(values.valueHour)) *
      (prepareNumber(duration) / 60);
    // prepareNumber(removeMask(values.valueHour)) *
    //   (prepareNumber(normalMinutes) / 60) +
    // prepareNumber(removeMask(values.valueHour)) *
    //   (prepareNumber(normalMinutes) / 60);

    const total =
      prepareNumber(removeMask(values.food)) +
      prepareNumber(removeMask(values.taxRoad)) +
      prepareNumber(removeMask(values.parking)) +
      prepareNumber(removeMask(values.accomodation)) +
      prepareNumber(removeMask(values.visit)) +
      totalKilometers +
      totalValueHour;

    return moneyMask(total.toFixed(0).toString());
  };

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <StyledContentTextFields>
            <StyledContentTextFields>
              {!!requestDetails?.client && (
                <Autocomplete
                  options={[
                    {
                      label: `${requestDetails.client.name} ${formatClientType(
                        requestDetails.client.type
                      )}`,
                      value: requestDetails.client._id,
                    },
                  ]}
                  fullWidth
                  disabled={true}
                  onChange={handleChangeClient}
                  defaultValue={{
                    label: `${requestDetails.client.name} ${formatClientType(
                      requestDetails.client.type
                    )}`,
                    value: requestDetails.client._id,
                  }}
                  onBlur={formik.handleBlur}
                  noOptionsText="Nenhuma opção encontrada"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      id="client"
                      variant="outlined"
                      InputLabelProps={{
                        style: {
                          fontSize: 18,
                          textTransform: 'uppercase',
                          background: '#fff',
                          paddingRight: 5,
                          color: '#000',
                        },
                        shrink: true,
                      }}
                      name="client"
                      label="Cliente"
                      value={formik.values.client}
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={formik.touched.client && formik.errors.client}
                    />
                  )}
                />
              )}
            </StyledContentTextFields>
            <StyledContentTextFields>
              {!!requestDetails?.type && (
                <TextField
                  fullWidth
                  id="type"
                  variant="outlined"
                  name="type"
                  label="Tipo do atendimento"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                  select
                  defaultValue={requestDetails?.type}
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                >
                  <MenuItem value={TypeRequestEnum.REMOTO}>Remoto</MenuItem>
                  <MenuItem value={TypeRequestEnum.PRESENTIAL}>
                    Presencial
                  </MenuItem>
                </TextField>
              )}

              {!!requestDetails?.status && (
                <TextField
                  fullWidth
                  id="status"
                  variant="outlined"
                  name="status"
                  label="Status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                  select
                  defaultValue={requestDetails.status}
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                >
                  <MenuItem value={StatusRequestEnum.PENDING}>
                    Em aberto
                  </MenuItem>
                  <MenuItem value={StatusRequestEnum.COMPLETED}>
                    Finalizado
                  </MenuItem>
                </TextField>
              )}
            </StyledContentTextFields>
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDateClosing"
              type="date"
              variant="outlined"
              name="startDateClosing"
              label="Data de início"
              inputProps={{
                max: new Date().toISOString().split('T')[0],
              }}
              hiddenLabel
              value={formik.values.startDateClosing}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateClosing &&
                Boolean(formik.errors.startDateClosing)
              }
              helperText={
                formik.touched.startDateClosing &&
                formik.errors.startDateClosing
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="startDateTime"
              type="time"
              variant="outlined"
              name="startDateTime"
              label="Hora de início"
              hiddenLabel
              value={formik.values.startDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateTime &&
                Boolean(formik.errors.startDateTime)
              }
              helperText={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDateClosing"
              type="date"
              variant="outlined"
              name="endDateClosing"
              label="Data de fim"
              inputProps={{
                min: formik.values.startDateClosing
                  ? formik.values.startDateClosing
                  : undefined,
              }}
              hiddenLabel
              value={formik.values.endDateClosing}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endDateClosing &&
                Boolean(formik.errors.endDateClosing)
              }
              helperText={
                formik.touched.endDateClosing && formik.errors.endDateClosing
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDateTime"
              type="time"
              variant="outlined"
              name="endDateTime"
              label="Hora de fim"
              hiddenLabel
              value={formik.values.endDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endDateTime && Boolean(formik.errors.endDateTime)
              }
              helperText={
                formik.touched.endDateTime && formik.errors.endDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={5}
            name="description"
            label="Descrição do serviço"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />

          <TextField
            fullWidth
            id="observation"
            type="observation"
            variant="outlined"
            multiline
            rows={2}
            name="observation"
            label="Observação (interna)"
            value={formik.values.observation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            error={
              formik.touched.observation && Boolean(formik.errors.observation)
            }
            helperText={formik.touched.observation && formik.errors.observation}
          />

          <FormGroup>
            <FormControlLabel
              checked={formik.values.chargeRequest}
              onChange={(e, checked) =>
                formik.setFieldValue('chargeRequest', checked)
              }
              control={<Switch />}
              label={
                formik.values.chargeRequest
                  ? 'Cobrar atendimento (o atendimento será cobrado)'
                  : 'Cobrar atendimento (o atendimento não será cobrado)'
              }
            />
          </FormGroup>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="valueHour"
              variant="outlined"
              name="valueHour"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor da hora"
              value={formik.values.valueHour}
              onChange={handleChangeMoney('valueHour')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.valueHour && Boolean(formik.errors.valueHour)
              }
              helperText={formik.touched.valueHour && formik.errors.valueHour}
            />

            <TextField
              fullWidth
              id="extraValueHour"
              variant="outlined"
              name="extraValueHour"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor da hora extra"
              value={formik.values.extraValueHour}
              onChange={handleChangeMoney('extraValueHour')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.extraValueHour &&
                Boolean(formik.errors.extraValueHour)
              }
              helperText={
                formik.touched.extraValueHour && formik.errors.extraValueHour
              }
            />

            <TextField
              fullWidth
              id="weekendValueHour"
              variant="outlined"
              name="weekendValueHour"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor da hora no fim de semana"
              value={formik.values.weekendValueHour}
              onChange={handleChangeMoney('weekendValueHour')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.weekendValueHour &&
                Boolean(formik.errors.weekendValueHour)
              }
              helperText={
                formik.touched.weekendValueHour &&
                formik.errors.weekendValueHour
              }
            />
            <TextField
              fullWidth
              id="food"
              variant="outlined"
              name="food"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Alimentação"
              value={formik.values.food}
              onChange={handleChangeMoney('food')}
              onBlur={formik.handleBlur}
              error={formik.touched.food && Boolean(formik.errors.food)}
              helperText={formik.touched.food && formik.errors.food}
            />

            <TextField
              fullWidth
              id="taxRoad"
              variant="outlined"
              name="taxRoad"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Pedágio"
              value={formik.values.taxRoad}
              onChange={handleChangeMoney('taxRoad')}
              onBlur={formik.handleBlur}
              error={formik.touched.taxRoad && Boolean(formik.errors.taxRoad)}
              helperText={formik.touched.taxRoad && formik.errors.taxRoad}
            />
          </StyledContentTextFields>
          <StyledContentTextFields>
            <TextField
              fullWidth
              id="parking"
              variant="outlined"
              name="parking"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Estacionamento"
              value={formik.values.parking}
              onChange={handleChangeMoney('parking')}
              onBlur={formik.handleBlur}
              error={formik.touched.parking && Boolean(formik.errors.parking)}
              helperText={formik.touched.parking && formik.errors.parking}
            />
            <TextField
              fullWidth
              id="accomodation"
              variant="outlined"
              name="accomodation"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Hospedagem"
              value={formik.values.accomodation}
              onChange={handleChangeMoney('accomodation')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.accomodation &&
                Boolean(formik.errors.accomodation)
              }
              helperText={
                formik.touched.accomodation && formik.errors.accomodation
              }
            />

            <TextField
              fullWidth
              id="visit"
              variant="outlined"
              name="visit"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Visita"
              value={formik.values.visit}
              onChange={handleChangeMoney('visit')}
              onBlur={formik.handleBlur}
              error={formik.touched.visit && Boolean(formik.errors.visit)}
              helperText={formik.touched.visit && formik.errors.visit}
            />

            <TextField
              fullWidth
              id="mileage"
              variant="outlined"
              name="mileage"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Quilômetros"
              value={formik.values.mileage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mileage && Boolean(formik.errors.mileage)}
              helperText={formik.touched.mileage && formik.errors.mileage}
            />

            <TextField
              fullWidth
              id="valueMileage"
              variant="outlined"
              name="valueMileage"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor do quilômetro"
              value={formik.values.valueMileage}
              onChange={handleChangeMoney('valueMileage')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.valueMileage &&
                Boolean(formik.errors.valueMileage)
              }
              helperText={
                formik.touched.valueMileage && formik.errors.valueMileage
              }
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <StyledContentTextFields flex={2}>
              <TextField
                fullWidth
                id="duration"
                variant="outlined"
                name="duration"
                label="Duração em minutos"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                disabled
                value={duration}
                error={
                  formik.touched.duration && Boolean(formik.errors.duration)
                }
                helperText={formik.touched.duration && formik.errors.duration}
              />

              <TextField
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                style={{ color: '#000 !important' }}
                label="Total"
                disabled
                value={calcTotal(formik.values, duration)}
              />
            </StyledContentTextFields>
            <StyledContentTextFields flex={3}></StyledContentTextFields>
          </StyledContentTextFields>
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate(-1)}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>{' '}
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default EditClosingRequestForm;
