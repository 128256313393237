import api from '../services/api';

interface Data {
  email: string;
  password: string;
}

export const signinRequest = async (data: Data): Promise<any> => {
  const result = await api.post(
    '/api/signin',
    {
      username: data.email,
      password: data.password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  return result?.data;
};

export default {
  signinRequest,
};
