import * as yup from 'yup';

export const initialHubFormValues = {
  client: '',
  name: '',
  description: '',
  url: '',
  folder: '',
  username: '',
  password: '',
};

export interface HubFormProps {
  client: string;
  name: string;
  description: string;
  url: string;
  folder: string;
  username: string;
  password: string;
}

export const validationSchema = yup.object({
  client: yup.string().required('Campo obrigátorio'),
  description: yup.string(),
  name: yup.string().required('Campo obrigátorio'),
  url: yup.string().required('Campo obrigátorio'),
  folder: yup.string().required('Campo obrigátorio'),
  username: yup.string().required('Campo obrigátorio'),
  password: yup.string().required('Campo obrigátorio'),
});
