/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useFormik } from 'formik';
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  ClientFormProps,
  ClientTypeEnum,
  initialClientFormValues,
  validationSchema,
} from './schema';
import { maskCnpjOrCpf } from '../../utils/mask';
import { maskPhone } from '../../utils/phone';
import { maskCep } from '../../utils/cep';
import { moneyMask } from '../../utils/money';

interface Props {
  isLoading: boolean;
  client: any;
  onSubmit: (values: ClientFormProps) => void;
}

const EditClientForm: React.FC<Props> = ({
  isLoading,
  onSubmit,
  client,
}): React.ReactElement => {
  const navigate = useNavigate();

  const prepareDate = (date: Date): string => {
    if (!date) return '';
    const data = new Date(date);
    const month = data.getMonth() + 1;
    const monthValue = month < 10 ? `0${month}` : `${month}`;
    const day = data.getDate();
    const dayValue = day < 10 ? `0${day}` : `${day}`;
    return `${data.getFullYear()}-${monthValue}-${dayValue}`;
  };

  const prepareClient = (client: any) => {
    delete client._id;
    delete client.id;
    delete client._v;
    delete client.__v;
    return {
      ...client,
      identification: maskCnpjOrCpf(client.identification),
      phoneOne: maskPhone(client.phoneOne),
      phoneTwo: maskPhone(client.phoneTwo),
      cep: maskCep(client.cep),
      startDateService: prepareDate(client.startDateService),
      contractValue: moneyMask(client.contractValue),
      food: moneyMask(client.food),
      taxRoad: moneyMask(client.taxRoad),
      parking: moneyMask(client.parking),
      accomodation: moneyMask(client.accomodation),
      visit: moneyMask(client.visit),
      valueMileage: moneyMask(client.valueMileage),
      valueHour: moneyMask(client.valueHour),
      extraValueHour: moneyMask(client.extraValueHour),
      weekendValueHour: moneyMask(client.weekendValueHour),
    };
  };

  const formik = useFormik({
    initialValues: client ? prepareClient(client) : initialClientFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const handleChangeIdentification = (event: any): void => {
    const value = maskCnpjOrCpf(event.target.value);

    formik.setFieldValue('identification', value);
  };

  const handleChangePhone = (key: string, event: any): void => {
    const value = maskPhone(event.target.value);

    formik.setFieldValue(key, value);
  };

  const handleChangeCep = (event: any): void => {
    const value = maskCep(event.target.value);

    formik.setFieldValue('cep', value);
  };

  const handleChangeMoney =
    (key: string) =>
    (event: any): void => {
      const value = moneyMask(event.target.value);

      formik.setFieldValue(key, value);
    };

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <Typography variant="h6">Dados Cadastrais </Typography>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="name"
              variant="outlined"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              name="name"
              label="Razão Social / Nome Completo"
              required
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              fullWidth
              id="fantasyName"
              variant="outlined"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              name="fantasyName"
              label="Nome Fantasia / Nome Abreviado"
              value={formik.values.fantasyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fantasyName && Boolean(formik.errors.fantasyName)
              }
              helperText={
                formik.touched.fantasyName && formik.errors.fantasyName
              }
            />
          </StyledContentTextFields>
          <StyledContentTextFields>
            <TextField
              fullWidth
              id="identification"
              variant="outlined"
              name="identification"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="CNPJ / CPF"
              required
              value={formik.values.identification}
              onChange={handleChangeIdentification}
              onBlur={formik.handleBlur}
              error={
                formik.touched.identification &&
                Boolean(formik.errors.identification)
              }
              helperText={
                formik.touched.identification && formik.errors.identification
              }
            />
            <TextField
              fullWidth
              id="stateRegistration"
              variant="outlined"
              name="stateRegistration"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Inscrição Estadual"
              value={formik.values.stateRegistration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.stateRegistration &&
                Boolean(formik.errors.stateRegistration)
              }
              helperText={
                formik.touched.stateRegistration &&
                formik.errors.stateRegistration
              }
            />
            <TextField
              fullWidth
              id="municipalRegistration"
              variant="outlined"
              name="municipalRegistration"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Inscrição Municipal"
              value={formik.values.municipalRegistration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.municipalRegistration &&
                Boolean(formik.errors.municipalRegistration)
              }
              helperText={
                formik.touched.municipalRegistration &&
                formik.errors.municipalRegistration
              }
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDateService"
              type="date"
              variant="outlined"
              name="startDateService"
              label="Data de início do atendimento"
              inputProps={{
                max: new Date().toISOString().split('T')[0],
              }}
              hiddenLabel
              value={formik.values.startDateService}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateService &&
                Boolean(formik.errors.startDateService)
              }
              helperText={
                formik.touched.startDateService &&
                formik.errors.startDateService
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="startTimeService"
              type="time"
              variant="outlined"
              name="startTimeService"
              label="Horário de atendimento"
              hiddenLabel
              value={formik.values.startTimeService}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startTimeService &&
                Boolean(formik.errors.startTimeService)
              }
              helperText={
                formik.touched.startTimeService &&
                formik.errors.startTimeService
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              id="endTimeService"
              type="time"
              variant="outlined"
              name="endTimeService"
              label="Fim do horário de atendimento"
              hiddenLabel
              value={formik.values.endTimeService}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endTimeService &&
                Boolean(formik.errors.endTimeService)
              }
              helperText={
                formik.touched.endTimeService && formik.errors.endTimeService
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <Typography variant="h6">Dados de Contato</Typography>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="phoneOne"
              variant="outlined"
              name="phoneOne"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              inputProps={{ maxLength: 15 }}
              label="Telefone 01"
              value={formik.values.phoneOne}
              onChange={(event) => handleChangePhone('phoneOne', event)}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneOne && Boolean(formik.errors.phoneOne)}
              helperText={formik.touched.phoneOne && formik.errors.phoneOne}
            />
            <TextField
              fullWidth
              id="contactOne"
              variant="outlined"
              name="contactOne"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Nome do Contato 01"
              value={formik.values.contactOne}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactOne && Boolean(formik.errors.contactOne)
              }
              helperText={formik.touched.contactOne && formik.errors.contactOne}
            />

            <TextField
              fullWidth
              id="phoneTwo"
              variant="outlined"
              name="phoneTwo"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Telefone 02"
              inputProps={{ maxLength: 15 }}
              value={formik.values.phoneTwo}
              onChange={(event) => handleChangePhone('phoneTwo', event)}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneTwo && Boolean(formik.errors.phoneTwo)}
              helperText={formik.touched.phoneTwo && formik.errors.phoneTwo}
            />
            <TextField
              fullWidth
              id="contactTwo"
              variant="outlined"
              name="contactTwo"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Nome do Contato 02"
              value={formik.values.contactTwo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contactTwo && Boolean(formik.errors.contactTwo)
              }
              helperText={formik.touched.contactTwo && formik.errors.contactTwo}
            />
          </StyledContentTextFields>
          <StyledContentTextFields>
            <StyledContentTextFields>
              <TextField
                fullWidth
                id="emailOne"
                variant="outlined"
                name="emailOne"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="E-mail 01"
                value={formik.values.emailOne}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.emailOne && Boolean(formik.errors.emailOne)
                }
                helperText={formik.touched.emailOne && formik.errors.emailOne}
              />
              <TextField
                fullWidth
                id="emailTwo"
                variant="outlined"
                name="emailTwo"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="E-mail 02"
                value={formik.values.emailTwo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.emailTwo && Boolean(formik.errors.emailTwo)
                }
                helperText={formik.touched.emailTwo && formik.errors.emailTwo}
              />
            </StyledContentTextFields>
            <StyledContentTextFields>
              <StyledContentTextFields>
                <TextField
                  fullWidth
                  id="emailSendInvoice"
                  variant="outlined"
                  name="emailSendInvoice"
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  label="E-mail onde será enviado a nota"
                  value={formik.values.emailSendInvoice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.emailSendInvoice &&
                    Boolean(formik.errors.emailSendInvoice)
                  }
                  helperText={
                    formik.touched.emailSendInvoice &&
                    formik.errors.emailSendInvoice
                  }
                />
              </StyledContentTextFields>
              <StyledContentTextFields></StyledContentTextFields>
            </StyledContentTextFields>
          </StyledContentTextFields>

          <Typography variant="h6">Dados de Endereço</Typography>

          <StyledContentTextFields>
            <StyledContentTextFields>
              <TextField
                fullWidth
                id="address"
                variant="outlined"
                name="address"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="Endereço"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </StyledContentTextFields>
            <StyledContentTextFields>
              <TextField
                fullWidth
                id="number"
                variant="outlined"
                name="number"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="Número"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.number && Boolean(formik.errors.number)}
                helperText={formik.touched.number && formik.errors.number}
              />
              <TextField
                fullWidth
                id="district"
                variant="outlined"
                name="district"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="Bairro"
                value={formik.values.district}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.district && Boolean(formik.errors.district)
                }
                helperText={formik.touched.district && formik.errors.district}
              />
            </StyledContentTextFields>
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="additionalInformation"
              variant="outlined"
              name="additionalInformation"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Complemento"
              value={formik.values.additionalInformation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.additionalInformation &&
                Boolean(formik.errors.additionalInformation)
              }
              helperText={
                formik.touched.additionalInformation &&
                formik.errors.additionalInformation
              }
            />
            <TextField
              fullWidth
              id="state"
              variant="outlined"
              name="state"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Estado"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
            <TextField
              fullWidth
              id="city"
              variant="outlined"
              name="city"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Cidade"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
            <TextField
              fullWidth
              id="cep"
              variant="outlined"
              name="cep"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              inputProps={{ maxLength: 9 }}
              label="CEP"
              value={formik.values.cep}
              onChange={handleChangeCep}
              onBlur={formik.handleBlur}
              error={formik.touched.cep && Boolean(formik.errors.cep)}
              helperText={formik.touched.cep && formik.errors.cep}
            />
          </StyledContentTextFields>

          <Typography variant="h6">Observações</Typography>

          <TextField
            fullWidth
            id="observation"
            variant="outlined"
            name="observation"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            multiline
            rows={4}
            label="Observações"
            value={formik.values.observation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.observation && Boolean(formik.errors.observation)
            }
            helperText={formik.touched.observation && formik.errors.observation}
          />

          <Typography variant="h6">Dados de Contrato</Typography>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="type"
              variant="outlined"
              name="type"
              label="Tipo do cliente"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              required
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              select
            >
              <MenuItem value={ClientTypeEnum.LOOSE}>Avulso</MenuItem>
              <MenuItem value={ClientTypeEnum.MONTHLY}>Mensal</MenuItem>
              <MenuItem value={ClientTypeEnum.YEARLY}>Anual</MenuItem>
            </TextField>
            <TextField
              fullWidth
              id="contractValue"
              variant="outlined"
              name="contractValue"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor do contrato"
              value={formik.values.contractValue}
              onChange={handleChangeMoney('contractValue')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.contractValue &&
                Boolean(formik.errors.contractValue)
              }
              helperText={
                formik.touched.contractValue && formik.errors.contractValue
              }
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="hours"
              type="number"
              variant="outlined"
              name="hours"
              label="Horas do pacote"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              value={formik.values.hours}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.hours && Boolean(formik.errors.hours)}
              helperText={formik.touched.hours && formik.errors.hours}
            />

            <TextField
              fullWidth
              id="food"
              variant="outlined"
              name="food"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Alimentação"
              value={formik.values.food}
              onChange={handleChangeMoney('food')}
              onBlur={formik.handleBlur}
              error={formik.touched.food && Boolean(formik.errors.food)}
              helperText={formik.touched.food && formik.errors.food}
            />
            <TextField
              fullWidth
              id="taxRoad"
              variant="outlined"
              name="taxRoad"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Pedágio"
              value={formik.values.taxRoad}
              onChange={handleChangeMoney('taxRoad')}
              onBlur={formik.handleBlur}
              error={formik.touched.taxRoad && Boolean(formik.errors.taxRoad)}
              helperText={formik.touched.taxRoad && formik.errors.taxRoad}
            />

            <TextField
              fullWidth
              id="parking"
              variant="outlined"
              name="parking"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Estacionamento"
              value={formik.values.parking}
              onChange={handleChangeMoney('parking')}
              onBlur={formik.handleBlur}
              error={formik.touched.parking && Boolean(formik.errors.parking)}
              helperText={formik.touched.parking && formik.errors.parking}
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="accomodation"
              variant="outlined"
              name="accomodation"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Hospedagem"
              value={formik.values.accomodation}
              onChange={handleChangeMoney('accomodation')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.accomodation &&
                Boolean(formik.errors.accomodation)
              }
              helperText={
                formik.touched.accomodation && formik.errors.accomodation
              }
            />

            <TextField
              fullWidth
              id="visit"
              variant="outlined"
              name="visit"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Visita"
              value={formik.values.visit}
              onChange={handleChangeMoney('visit')}
              onBlur={formik.handleBlur}
              error={formik.touched.visit && Boolean(formik.errors.visit)}
              helperText={formik.touched.visit && formik.errors.visit}
            />

            <TextField
              fullWidth
              id="mileage"
              type="number"
              variant="outlined"
              name="mileage"
              label="Quilômetros"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              value={formik.values.mileage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mileage && Boolean(formik.errors.mileage)}
              helperText={formik.touched.mileage && formik.errors.mileage}
            />

            <TextField
              fullWidth
              id="valueMileage"
              variant="outlined"
              name="valueMileage"
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              label="Valor do Quilômetro"
              value={formik.values.valueMileage}
              onChange={handleChangeMoney('valueMileage')}
              onBlur={formik.handleBlur}
              error={
                formik.touched.valueMileage &&
                Boolean(formik.errors.valueMileage)
              }
              helperText={
                formik.touched.valueMileage && formik.errors.valueMileage
              }
            />
          </StyledContentTextFields>
          <StyledContentTextFields>
            <StyledContentTextFields>
              <TextField
                fullWidth
                id="valueHour"
                variant="outlined"
                name="valueHour"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="Valor da hora"
                value={formik.values.valueHour}
                onChange={handleChangeMoney('valueHour')}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.valueHour && Boolean(formik.errors.valueHour)
                }
                helperText={formik.touched.valueHour && formik.errors.valueHour}
              />

              <TextField
                fullWidth
                id="extraValueHour"
                variant="outlined"
                name="extraValueHour"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                label="Valor da hora extra"
                value={formik.values.extraValueHour}
                onChange={handleChangeMoney('extraValueHour')}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.extraValueHour &&
                  Boolean(formik.errors.extraValueHour)
                }
                helperText={
                  formik.touched.extraValueHour && formik.errors.extraValueHour
                }
              />
            </StyledContentTextFields>

            <StyledContentTextFields>
              <StyledContentTextFields>
                <TextField
                  fullWidth
                  id="weekendValueHour"
                  variant="outlined"
                  name="weekendValueHour"
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                  label="Valor da hora no final de semana"
                  value={formik.values.weekendValueHour}
                  onChange={handleChangeMoney('weekendValueHour')}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.weekendValueHour &&
                    Boolean(formik.errors.weekendValueHour)
                  }
                  helperText={
                    formik.touched.weekendValueHour &&
                    formik.errors.weekendValueHour
                  }
                />
              </StyledContentTextFields>
              <StyledContentTextFields></StyledContentTextFields>
            </StyledContentTextFields>
          </StyledContentTextFields>
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/clients')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>{' '}
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default EditClientForm;
