/* eslint-disable no-param-reassign */
export const formatMoney = (price: string | number): string => {
  const priceValue = Number(price) / 100;

  if (Number.isNaN(priceValue)) {
    return 'R$ 0,00';
  }

  return `${priceValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })}`;
};

export const moneyMask = (value: string | number): string => {
  if (!value) {
    return 'R$ 0,00';
  }

  value = value.toString().replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };

  const newValue = parseFloat(value) / 100;

  if (Number.isNaN(newValue)) {
    return 'R$ 0,00';
  }

  const result = new Intl.NumberFormat('pt-BR', options).format(newValue);

  return `R$ ${result}`;
};
