import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import { editClient, getClientDetails } from '../../api/client';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Card from '../../components/Card';
import { removeMask } from '../../utils/mask';
import Backbutton from '../../components/Backbutton';
import EditClientForm from '../../components/EditClientForm';

const EditClient: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { id } = useParams();

  const [client, setClient] = useState<any>(null);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && id) {
        const data = await getClientDetails(authenticate.token, id);

        setClient(data?.client);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitAddClient = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDateService, '23:59');

      if (id && authenticate.token) {
        await editClient(
          id,
          {
            ...values,
            identification: removeMask(values.identification),
            phoneOne: removeMask(values.phoneOne),
            phoneTwo: removeMask(values.phoneTwo),

            cep: removeMask(values.cep),
            food: removeMask(values.food),
            taxRoad: removeMask(values.taxRoad),
            parking: removeMask(values.parking),
            accomodation: removeMask(values.accomodation),
            visit: removeMask(values.visit),
            mileage: removeMask(values.mileage),
            valueHour: removeMask(values.valueHour),
            extraValueHour: removeMask(values.extraValueHour),
            contractValue: removeMask(values.contractValue),
            weekendValueHour: removeMask(values.weekendValueHour),
            valueMileage: removeMask(values.valueMileage),
            startDateService: startDate,
          },
          authenticate.token
        );

        toast('Cliente editado com sucesso!', { type: 'success' });
        navigate('/dash/clients');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path="/dash/clients"
        labels={['Clientes', 'Editar cliente']}
      />

      <Card>
        <EditClientForm
          client={client}
          isLoading={false}
          onSubmit={handleSubmitAddClient}
        />
      </Card>
    </StyledRoot>
  );
};

export default EditClient;
