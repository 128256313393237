/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Card from '../../components/Card';
import Backbutton from '../../components/Backbutton';
import { finishInternalTask, getTask } from '../../api/task';
import DetailsInfo from '../../components/DetailsInfo';
import { formatDate } from '../../utils/date';
import TaskInternalFinishForm from '../../components/TaskInternalFinishForm';

const TaskInternalFinish: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();

  const queryTask = useMemo(() => {
    return queryParams.get('task') || undefined;
  }, [queryParams]);

  const [task, setTask] = useState<any>();

  const getTaskData = useCallback(
    async (taskId: string) => {
      try {
        if (authenticate.token) {
          const data = await getTask(taskId, authenticate.token);
          setTask(data?.task);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (queryTask) {
      getTaskData(queryTask);
    }
  }, [getTaskData, queryTask]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitFinishTask = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDate, values.startDateTime);
      const endDate = prepareDate(values.endDate, values.endDateTime);

      await finishInternalTask(
        {
          ...values,
          startDate,
          endDate,
          task: task?._id,
        },
        authenticate.token
      );

      if (task) {
        navigate('/dash/tasks');
      } else {
        navigate('/dash/requests');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path={`/dash/tasks`}
        labels={['Tarefas', 'Concluir tarefa interna']}
      />

      {task && (
        <Card>
          <DetailsInfo
            isLoading={false}
            sections={[
              {
                rows: [
                  {
                    key: 'user',
                    label: 'Consultor',
                    transform: (user) =>
                      user.map((item: any) => item.name).join(', '),
                  },
                  {
                    key: 'createdBy',
                    subkey: 'name',
                    label: 'Criado por',
                  },
                  {
                    key: 'startDate',
                    label: 'Data',
                    transform: formatDate,
                  },
                  {
                    key: 'description',
                    label: 'Descrição',
                    isHtml: true,
                  },
                ],
                data: task,
                title: 'Tarefa',
              },
            ]}
          />
        </Card>
      )}

      <Card>
        <TaskInternalFinishForm
          isLoading={false}
          onSubmit={handleSubmitFinishTask}
          task={task}
        />
      </Card>
    </StyledRoot>
  );
};

export default TaskInternalFinish;
