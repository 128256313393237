import React, { ReactElement } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { StyledRoot, StyledText } from './styles';

const Loading: React.FC = (): ReactElement => {
  return (
    <StyledRoot>
      <CircularProgress size={100} />
      <StyledText>Carregando</StyledText>
    </StyledRoot>
  );
};

export default Loading;
