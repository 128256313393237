import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  StyledEditRequestContainer,
  StyledEditRequestItem,
  StyledRoot,
} from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { deleteClient, getClientDetails } from '../../api/client';

import Card from '../../components/Card';
import DetailsInfo from '../../components/DetailsInfo';
import { isCpfValid } from '../../utils/cpf';
import { maskCnpjOrCpf } from '../../utils/mask';
import { maskPhone } from '../../utils/phone';
import { maskCep } from '../../utils/cep';
import { formatClientType } from '../Clients/utils';
import { moneyMask } from '../../utils/money';
import { formatOnlyDate } from '../../utils/date';
import Backbutton from '../../components/Backbutton';
import ConfirmModal from '../../components/ConfirmModal';

const ClientDetails: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();

  const navigate = useNavigate();

  const { id } = useParams();

  const [client, setClient] = useState<any>(null);

  const [open, setOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState('');

  const handleOpenDeleteModal = (id: string) => {
    setOpen(true);
    setClientToDelete(id);
  };

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteClient(authenticate.token, id);
      }

      toast('Cliente deletado com sucesso!', { type: 'success' });
      navigate('/dash/clients');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && id) {
        const data = await getClientDetails(authenticate.token, id);

        setClient(data?.client);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <React.Fragment>
      <StyledRoot>
        <div style={{ display: 'flex', flex: 1 }}>
          <Backbutton
            path="/dash/clients"
            labels={['Clientes', client?.name || 'Detalhes do cliente']}
          />
        </div>

        <Card>
          <StyledEditRequestContainer>
            <StyledEditRequestItem
              onClick={() => handleOpenDeleteModal(id || '')}
            >
              <DeleteIcon fontSize="large" color="error" />
              <div>Deletar cliente </div>
            </StyledEditRequestItem>
            <StyledEditRequestItem
              onClick={() => navigate(`/dash/clients/${id}/edit`)}
            >
              <EditNoteIcon fontSize="large" color="primary" />
              <div>Editar cliente </div>
            </StyledEditRequestItem>
          </StyledEditRequestContainer>
          <DetailsInfo
            isLoading={false}
            sections={[
              {
                title: 'Dados Cadastrais',
                rows: [
                  {
                    label: 'Razão social',
                    key: 'name',
                  },
                  {
                    label: 'Nome fantasia',
                    key: 'fantasyName',
                  },
                  {
                    label:
                      client?.identification &&
                      isCpfValid(client?.identification)
                        ? 'CPF'
                        : 'CNPJ',
                    key: 'identification',
                    transform: maskCnpjOrCpf,
                  },
                  {
                    label: 'Inscrição estadual',
                    key: 'stateRegistration',
                  },
                  {
                    label: 'Inscrição municipal',
                    key: 'municipalRegistration',
                  },
                  {
                    label: 'Data de início de atendimento',
                    key: 'startDateService',
                    transform: formatOnlyDate,
                  },
                  {
                    label: 'Horário de atendimento',
                    key: 'startTimeService',
                  },
                  {
                    label: 'Fim do horário de atendimento',
                    key: 'endTimeService',
                  },
                ],
                data: client,
              },
              {
                title: 'Dados de Contato',
                rows: [
                  {
                    label: 'Telefone 01',
                    key: 'phoneOne',
                    transform: maskPhone,
                  },
                  {
                    label: 'Nome do contato 01',
                    key: 'contactOne',
                  },
                  {
                    label: 'Telefone 02',
                    key: 'phoneTwo',
                    transform: maskPhone,
                  },
                  {
                    label: 'Nome do contato 02',
                    key: 'contactTwo',
                  },
                  {
                    label: 'E-mail 01',
                    key: 'emailOne',
                  },
                  {
                    label: 'E-mail 02',
                    key: 'emailTwo',
                  },
                  {
                    label: 'E-mail onde será enviado a nota',
                    key: 'emailSendInvoice',
                  },
                ],
                data: client,
              },
              {
                title: 'Dados de Endereço',
                rows: [
                  {
                    label: 'Endereço',
                    key: 'address',
                  },
                  {
                    label: 'Número',
                    key: 'number',
                  },
                  {
                    label: 'Bairro',
                    key: 'district',
                  },
                  {
                    label: 'Complemento',
                    key: 'additionalInformation',
                  },
                  {
                    label: 'Estado',
                    key: 'state',
                  },
                  {
                    label: 'Cidade',
                    key: 'city',
                  },
                  {
                    label: 'CEP',
                    key: 'cep',
                    transform: maskCep,
                  },
                ],
                data: client,
              },
              {
                title: 'Observações',
                rows: [
                  {
                    label: 'Observações',
                    key: 'observation',
                  },
                ],
                data: client,
              },

              {
                title: 'Dados de Contrato',
                rows: [
                  {
                    label: 'Tipo do cliente',
                    key: 'type',
                    transform: (value) => formatClientType(value, false),
                  },
                  {
                    label: 'Valor do contrato',
                    key: 'contractValue',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Horas do pacote',
                    key: 'hours',
                    transform: (value) => `${value}h`,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Alimentação',
                    key: 'food',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Pedágio',
                    key: 'taxRoad',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Estacionamento',
                    key: 'parking',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Hospedagem',
                    key: 'accomodation',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Visita',
                    key: 'visit',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Quilômetros',
                    key: 'mileage',
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },

                  {
                    label: 'Valor por cada quilômetro',
                    key: 'valueMileage',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Valor total dos quilômetros',
                    key: 'valueMileage',
                    transformBody: (value) => {
                      const total = value.mileage * value.valueMileage;
                      return moneyMask(total);
                    },
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Valor da hora',
                    key: 'valueHour',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Valor da hora extra',
                    key: 'extraValueHour',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                  {
                    label: 'Valor da hora no final de semana',
                    key: 'weekendValueHour',
                    transform: moneyMask,
                    hidden: (item) => {
                      const value = Number(item);
                      return !value;
                    },
                  },
                ],
                data: client,
              },
            ]}
          />
        </Card>
      </StyledRoot>

      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(clientToDelete)}
        title="Deletar cliente"
        message="Deseja confirmar a exclusão do cliente?"
      />
    </React.Fragment>
  );
};

export default ClientDetails;
