/* eslint-disable func-names */

import * as yup from 'yup';
import { isCpfValid } from './cpf';
import { isCnpjValid } from './cnpj';

declare module 'yup' {
  interface StringSchema {
    validCpfOrCnpj(message: string): StringSchema;
  }
}

yup.addMethod(yup.string, 'validCpfOrCnpj', function (errorMessage) {
  return this.test('test-cpf-or-cnpj', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: errorMessage });
    }

    return (
      isCpfValid(value) ||
      isCnpjValid(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

export default yup;
