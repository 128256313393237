import styled from 'styled-components';

export const StyledRoot = styled.span`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const StyledDrawer = styled('div')({
  maxWidth: 275,
  minHeight: '100vh',
  background: 'red',
  '@media (min-width: 900px) ': {
    width: 275,
    background: 'red',
  },
});

export const StyledHeader = styled('div')({
  background: '#fff',
  width: '100%',
  height: '75px',

  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 20,
  padding: 32,
  boxSizing: 'border-box',
  fontWeight: 'bold',
  fontSize: 20,
});

export const StyledContainer = styled('div')({
  background: '#dadfde',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '@media (min-width: 900px) ': {
    width: 'calc(100% - 275px)',
  },
});

export const StyledOutlet = styled('div')({
  padding: '32px 32px',
  boxSizing: 'border-box',
});
