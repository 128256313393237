import * as yup from 'yup';

export const initialLoginFormValues = {
  password: '',
};

export interface LoginFormProps {
  password: string;
}

export const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .required('Senha obrigatório'),
});
