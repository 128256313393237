/* eslint-disable no-underscore-dangle */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Chip } from '@mui/material';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import TableTitle from '../../components/Table/TableTitle';
import { getClosingDetails } from '../../api/closing';
import {
  calculateDiffDatesInSeconds,
  formatOnlyDateWithDayOfWeek,
  formatSeconds,
} from '../../utils/date';
import { formatMoney } from '../../utils/money';
import useShowMoney from '../../recoil/hooks/showMoney';
import Backbutton from '../../components/Backbutton';
import TableExpansive from '../../components/TableExpansive';
import ClosingRequestDetailsList from '../ClosingRequestDetails/ClosingRequestDetailsList';
import Card from '../../components/Card';
import DetailsInfo from '../../components/DetailsInfo';
import { formatMonth } from '../../utils/month';
import NotFoundItems from '../../components/NotFoundItems';

const ClosingDetails: React.FC = (): ReactElement => {
  const [queryParams] = useSearchParams();

  const requestDefault = useMemo(() => {
    return queryParams.get('request') || undefined;
  }, [queryParams]);

  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { closingId } = useParams();

  const { showMoney, setShowMoney } = useShowMoney();

  const [closingDetails, setClosingDetails] = useState<any>({});

  const [expandAll, setExpandAll] = useState(false);

  const getData = useCallback(
    async (id: string) => {
      try {
        if (authenticate.token) {
          const data = await getClosingDetails(authenticate.token, id);

          setClosingDetails(data?.data || {});
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (closingId) {
      getData(closingId);
    }
  }, [getData, closingId]);

  const formatStatusIcon = (status: string): ReactElement => {
    if (status === 'pending') {
      return <Chip label="Em andamento" color="warning" />;
    }

    return <Chip label="Finalizado" color="primary" />;
  };

  const showTotalValue = (value: any) => {
    if (!showMoney) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              height: 10,
              width: 75,
              borderRadius: 4,
              background: '#ccc',
              alignItems: 'center',
            }}
          ></div>
        </div>
      );
    }
    return formatMoney(value);
  };

  const handleEdit = (requestId: string) => {
    navigate(`/dash/closing/${closingId}/requests/${requestId}`);
  };

  const handleExpensive = (data: any) => {
    return (
      <ClosingRequestDetailsList
        requestDetails={data}
        handleEdit={() => {
          handleEdit(data._id);
        }}
      />
    );
  };

  const formatClosingStatus = (item: any): string => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    if (month === item.month && year === item.year) {
      return 'Em aberto';
    }

    if (item.status === 'pending') {
      return 'Em aprovação';
    }

    return 'Fechado';
  };

  return (
    <StyledRoot>
      <div style={{ display: 'flex', flex: 1 }}>
        <Backbutton
          path="/dash/closing"
          labels={['Fechamentos', 'Detalhes do fechamento']}
        />

        <TableTitle
          title=""
          actions={[
            {
              onAction: () => {
                setShowMoney(!showMoney);
              },
              icon: showMoney ? (
                <VisibilityOffIcon
                  style={{ width: 36, height: 36 }}
                  color="primary"
                />
              ) : (
                <VisibilityIcon
                  style={{ width: 36, height: 36 }}
                  color="primary"
                />
              ),
            },
          ]}
          button={{
            text: expandAll ? 'Ocultar todos' : 'Expandir todos',
            icon: expandAll ? <ExpandLessIcon /> : <ExpandMoreIcon />,
            onAction: () => setExpandAll(!expandAll),
          }}
        />
      </div>

      <Card>
        <DetailsInfo
          isLoading={false}
          sections={[
            {
              rows: [
                {
                  key: 'client',
                  subkey: 'name',
                  label: 'Cliente',
                },
                {
                  key: 'month',
                  label: 'Mês',
                  transform: formatMonth,
                },
                {
                  key: 'year',
                  label: 'Ano',
                },
                {
                  key: 'status',
                  label: 'Status',
                  transform: formatClosingStatus,
                },

                {
                  key: 'requests',
                  label: 'Atendimentos',
                  transformBody: (body: any) => {
                    return body?.requests.length;
                  },
                },
              ],
              data: closingDetails,
              title: 'Fechamento',
            },
          ]}
        />
      </Card>

      <TableExpansive
        header={{
          backgroundColor: '#38A658',
          color: '#fff',
          columns: [
            {
              label: 'Consultor',
            },
            {
              label: 'Data',
            },
            {
              label: 'Duração',
            },
            {
              label: 'Cobrar',
            },
            {
              label: 'Total',
            },
            {
              label: 'Status',
            },
          ],
        }}
        body={{
          rows: [
            { key: 'user', subkey: 'name' },
            { key: 'startDate', transform: formatOnlyDateWithDayOfWeek },
            {
              key: 'endDate',
              transformBody: (params: any) => {
                const seconds = calculateDiffDatesInSeconds(
                  params.startDateClosing,
                  params.endDateClosing
                );
                return formatSeconds(seconds);
              },
            },
            {
              key: 'chargeRequest',
              transform: (charge) => (charge ? 'Sim' : 'Não'),
            },
            { key: 'total', transformIcon: showTotalValue },
            { key: 'status', transformIcon: formatStatusIcon },
          ],
          onClick: (item) => {
            navigate(`/dash/closing/${closingId}/requests/${item._id}`);
          },
        }}
        data={closingDetails?.requests || []}
        defaultExpansive={requestDefault}
        expandAll={expandAll}
        handleExpansive={handleExpensive}
      />

      {!closingDetails?.requests?.length && (
        <NotFoundItems
        // label={
        //   users.length
        //     ? 'Nenhum usuário encontrado para essa busca!'
        //     : undefined
        // }
        />
      )}
    </StyledRoot>
  );
};

export default ClosingDetails;
