import React, { ReactElement } from 'react';

import {
  StyledBar,
  StyledContainer,
  StyledContentIcon,
  StyledContentMiddle,
  StyledDescription,
  StyledDescriptionHidden,
  StyledRoot,
  StyledTitle,
} from './styles';
import NotificationDot from '../NotificationDot';

interface CardHomeProps {
  icon: ReactElement;
  title: string;
  number: string;
  description?: string;
  onClick?: () => void;
  notification?: {
    color: string;
  };
  hiddenValue?: boolean;
}

const CardHome: React.FC<CardHomeProps> = ({
  title,
  icon,
  number,
  description,
  onClick,
  notification,
  hiddenValue,
}): ReactElement => {
  return (
    <StyledRoot onClick={onClick}>
      <StyledContainer>
        <StyledBar></StyledBar>
        <StyledContentMiddle>
          <StyledTitle>{title}</StyledTitle>
          {hiddenValue ? (
            <StyledDescription>
              <StyledDescriptionHidden />
            </StyledDescription>
          ) : (
            <StyledDescription>{number}</StyledDescription>
          )}
        </StyledContentMiddle>
        <StyledContentIcon>{icon}</StyledContentIcon>
      </StyledContainer>
      <div>{description}</div>
      {notification && (
        <div style={{ position: 'absolute', top: 5, right: 5 }}>
          <NotificationDot color={notification.color} />
        </div>
      )}
    </StyledRoot>
  );
};

export default CardHome;
