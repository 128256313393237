import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
// import { editUser } from '../../api/users';
import useAuthenticate from '../../recoil/hooks/authenticate';
// import EditUserForm from '../../components/EditUserForm';
import Card from '../../components/Card';
import Backbutton from '../../components/Backbutton';
import EditUserForm from '../../components/EditUserForm';
import { editUser, getUser } from '../../api/users';

const EditUser: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);

  const { id } = useParams();

  console.log(id);

  const getData = useCallback(async () => {
    try {
      if (authenticate.token && id) {
        const data = await getUser(authenticate.token, id);

        setUser(data?.user);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSubmitEditUser = async (values: any) => {
    try {
      if (id && authenticate.token) {
        await editUser(id, values, authenticate.token);

        toast('Usuário editado com sucesso!', { type: 'success' });
        navigate('/dash/users');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/users" labels={['Usuários', 'Editar usuário']} />

      <Card>
        <EditUserForm
          isLoading={false}
          user={user}
          onSubmit={handleSubmitEditUser}
        />
      </Card>
    </StyledRoot>
  );
};

export default EditUser;
