import 'react-toastify/dist/ReactToastify.css';

import React, { ReactElement } from 'react';
import { ToastContainer } from 'react-toastify';

import { HashRouter, Routes, Route } from 'react-router-dom';

import NotFound from './pages/NotFound';
import LoginPage from './pages/Login';
import DashboardPage from './pages/Dashboard';
import HomePage from './pages/Home';
import UsersPage from './pages/Users';
import ClientsPage from './pages/Clients';
import ClientDetailsPage from './pages/ClientDetails';
import EditClientPage from './pages/EditClient';
import RequestsPage from './pages/Requests';
import RequestDetailsEditPage from './pages/RequestDetailsEdit';
import ClosingsPage from './pages/Closings';
import ClosingDetailsPage from './pages/ClosingDetails';
import ClosingRequestDetailsPage from './pages/ClosingRequestDetails';
import ClosingRequestDetailsEditPage from './pages/ClosingRequestDetailsEdit';
import AddTaskPage from './pages/AddTask';
import AddUserPage from './pages/AddUser';
import EditUserPage from './pages/EditUser';
import EditUserPasswordPage from './pages/EditUserPassword';
import AddClientPage from './pages/AddClient';
import AddRequestPage from './pages/AddRequest';
import RequestDetailsPage from './pages/RequestDetails';
import TasksPage from './pages/Tasks';
import EditTaskPage from './pages/EditTask';
import HubPage from './pages/Hub';
import AddHubPage from './pages/AddHub';
import HubClientPage from './pages/HubClient';
import TaskInternalFinishPage from './pages/TaskInternalFinish';

import ProtectedRoute from './Protected';

const App: React.FC = (): ReactElement => {
  return (
    <React.Fragment>
      <ToastContainer />

      <HashRouter>
        <Routes>
          <Route index element={<LoginPage />} />
          <Route path="dash" element={<ProtectedRoute />}>
            <Route element={<DashboardPage />}>
              <Route index element={<HomePage />} />
              <Route path="home" element={<HomePage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="users/create" element={<AddUserPage />} />
              <Route path="users/:id/edit" element={<EditUserPage />} />
              <Route
                path="users/:id/edit-password"
                element={<EditUserPasswordPage />}
              />

              <Route path="tasks" element={<TasksPage />} />
              <Route path="tasks/create" element={<AddTaskPage />} />
              <Route path="tasks/finish" element={<AddRequestPage />} />
              <Route path="tasks/:id/edit" element={<EditTaskPage />} />
              <Route
                path="tasks/internal/finish"
                element={<TaskInternalFinishPage />}
              />

              <Route path="clients" element={<ClientsPage />} />
              <Route path="clients/:id" element={<ClientDetailsPage />} />
              <Route path="clients/:id/edit" element={<EditClientPage />} />
              <Route path="clients/create" element={<AddClientPage />} />
              <Route path="requests" element={<RequestsPage />} />
              <Route path="requests/:id" element={<RequestDetailsPage />} />
              <Route
                path="requests/:id/edit"
                element={<RequestDetailsEditPage />}
              />
              <Route path="requests/create" element={<AddRequestPage />} />
              <Route path="closing" element={<ClosingsPage />} />
              <Route path="hub" element={<HubPage />} />
              <Route path="hub/create" element={<AddHubPage />} />
              <Route path="hub/clients/:clientId" element={<HubClientPage />} />
              <Route
                path="closing/:closingId"
                element={<ClosingDetailsPage />}
              />
              <Route
                path="closing/:closingId/requests/:requestId/resum"
                element={<ClosingRequestDetailsPage />}
              />
              <Route
                path="closing/:closingId/requests/:requestId"
                element={<ClosingRequestDetailsEditPage />}
              />
              <Route path="*" element={<NotFound header />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </React.Fragment>
  );
};

export default App;
