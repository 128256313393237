import { useRecoilState } from 'recoil';

import showMoneyAtom from '../atoms/showMoney';

const useShowMoney = (): any => {
  const [showMoney, setShowMoney] = useRecoilState(showMoneyAtom);

  return { showMoney, setShowMoney };
};

export default useShowMoney;
