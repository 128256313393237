import React, { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthenticate from './recoil/hooks/authenticate';

interface Props {
  redirectPath?: string;
}

const ProtectedRoute: React.FC<Props> = ({
  redirectPath = '/',
}): ReactElement => {
  const { authenticate } = useAuthenticate();

  if (!authenticate.isAuthenticate) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
