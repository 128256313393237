import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import ReactDOM from 'react-dom';

import { ThemeProvider } from '@mui/material';
import App from './App';
// import Version from './components/Version';
import Connect from './Connect';

import * as serviceWorker from './serviceWorkerRegistration';
import { theme } from './theme';

// const resources = ['Interface amigável'];

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <RecoilNexus />
        <Connect>
          <App />
        </Connect>
      </RecoilRoot>
      {/* <Version version="v0.0.1" resources={resources} /> */}
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
