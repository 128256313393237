import * as yup from 'yup';

export enum TypeRequestEnum {
  REMOTO = 'remote',
  PRESENTIAL = 'presential',
}

export enum RecurrencyRequestEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const initialRequestFormValues: RequestFormProps = {
  client: '',
  user: [],
  type: TypeRequestEnum.REMOTO,
  startDate: null,
  startDateTime: null,
  description: '',
  internal: false,
  repeat: false,
  clientName: '',
  singleClient: false,
  repeatOften: RecurrencyRequestEnum.DAILY,
};

export interface RequestFormProps {
  client: string;
  user: string[];
  startDate: Date | null;
  startDateTime: Date | null;
  description: string;
  type: TypeRequestEnum;
  internal: boolean;
  repeat: boolean;
  clientName: string;
  singleClient: boolean;
  repeatOften: RecurrencyRequestEnum;
}

export const validationSchema = yup.object({
  user: yup.array().required('Campo obrigátorio'),
  startDate: yup.date().required('Campo obrigatório').nullable(),
  startDateTime: yup.string().nullable().required('Campo obrigatório'),
  description: yup.string().required('Campo obrigatório'),
  type: yup
    .mixed<TypeRequestEnum>()
    .oneOf(
      [TypeRequestEnum.REMOTO, TypeRequestEnum.PRESENTIAL],
      'Campo inválido'
    )
    .required('Campo obrigatório'),
});
