import React, { ReactElement, ReactNode } from 'react';

import { StyledRoot } from './styles';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

interface TableHeaderColumnsProps {
  label: string;
}

interface TableHeaderProps {
  color?: string;
  backgroundColor?: string;
  columns?: TableHeaderColumnsProps[];
}

interface TableOptions {
  label: string;
  onClick: (item: any) => void;
  disabled?: (item: any) => boolean;
}

interface TableBodyRowsProps {
  key: string;
  subkey?: string;
  transform?: (text: any) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement | string;
}

interface TableBodyProps {
  rows?: TableBodyRowsProps[];
  onClick?: (data: any) => void;
  showNotification?: (data: any) => string;
  options?: TableOptions[];
}

interface TableProps {
  header?: TableHeaderProps;
  body?: TableBodyProps;
  expandAll?: boolean;
  data?: any[];
  defaultExpansive?: string;
  handleExpansive?: (item: any) => ReactNode;
}

const TableExpansive: React.FC<TableProps> = ({
  header,
  body,
  data,
  expandAll,
  defaultExpansive,
  handleExpansive,
}): ReactElement => {
  return (
    <StyledRoot>
      {header && <TableHeader {...header} />}
      {body && (
        <TableBody
          {...body}
          data={data}
          expandAll={expandAll}
          handleExpansive={handleExpansive}
          defaultExpansive={defaultExpansive}
        />
      )}
    </StyledRoot>
  );
};

export default TableExpansive;
