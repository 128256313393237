import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import AddTaskForm from '../../components/AddTaskForm';
import Card from '../../components/Card';
import { getClients } from '../../api/client';
import { addTask } from '../../api/task';
import { getUsers } from '../../api/users';
import Backbutton from '../../components/Backbutton';

const AddTask: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);

  const getClientsData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getClients(authenticate.token);

        setClients(data?.clients || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getClientsData();
  }, [getClientsData]);

  const [users, setUsers] = useState([]);

  const getUsersData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getUsers(authenticate.token);

        setUsers(data?.users || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getUsersData();
  }, [getUsersData]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitAddTask = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDate, values.startDateTime);

      await addTask(
        {
          ...values,
          client: !values.internal ? values.client : undefined,
          startDate,
          repeatOften: values.repeat ? values.repeatOften : undefined,
        },
        authenticate.token
      );

      navigate('/dash/tasks');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/tasks" labels={['Tarefas', 'Nova tarefa']} />

      <Card>
        <AddTaskForm
          isLoading={false}
          onSubmit={handleSubmitAddTask}
          clients={clients}
          users={users}
        />
      </Card>
    </StyledRoot>
  );
};

export default AddTask;
