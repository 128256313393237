import { useEffect, useState } from 'react';

function getWindowWidth(): number {
  const { innerWidth } = window;

  return innerWidth;
}

export default function useWindowWidth(): number {
  const [width, setWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}
