import React from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  RecurrencyRequestEnum,
  RequestFormProps,
  TypeRequestEnum,
  initialRequestFormValues,
  validationSchema,
} from './schema';
import { formatClientType } from '../../pages/Clients/utils';

interface Props {
  clients: any[];
  users: any[];
  isLoading: boolean;
  onSubmit: (values: RequestFormProps) => void;
}

const AddTaskForm: React.FC<Props> = ({
  clients,
  users,
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialRequestFormValues,
    validationSchema,
    onSubmit,
  });

  const handleChangeClient = (event: any, value: any) => {
    formik.setFieldValue('client', value?.value || '');
  };

  const handleChangeUser = (_event: any, value: any) => {
    formik.setFieldValue('user', value?.map((item: any) => item.value) || []);
  };

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <FormGroup>
            <FormControlLabel
              checked={formik.values.internal}
              onChange={(e, checked) =>
                formik.setFieldValue('internal', checked)
              }
              control={<Switch />}
              label="Tarefa interna"
            />
          </FormGroup>

          {!formik.values.internal && (
            <FormGroup>
              <FormControlLabel
                checked={formik.values.singleClient}
                onChange={(e, checked) =>
                  formik.setFieldValue('singleClient', checked)
                }
                control={<Switch />}
                label="Cliente avulso"
              />
            </FormGroup>
          )}

          <StyledContentTextFields>
            {!formik.values.internal && !formik.values.singleClient && (
              <Autocomplete
                options={clients.map((item) => ({
                  label: `${item.name} ${formatClientType(item.type)}`,
                  value: item.id,
                }))}
                fullWidth
                disabled={formik.values.internal}
                onChange={handleChangeClient}
                onBlur={formik.handleBlur}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="client"
                    variant="outlined"
                    name="client"
                    label="Cliente"
                    value={formik.values.client}
                    error={
                      formik.touched.client && Boolean(formik.errors.client)
                    }
                    helperText={formik.touched.client && formik.errors.client}
                    InputLabelProps={{
                      style: {
                        fontSize: 18,
                        textTransform: 'uppercase',
                        background: '#fff',
                        paddingRight: 5,
                        color: formik.values.internal ? '#777' : '#000',
                      },
                      shrink: true,
                    }}
                  />
                )}
              />
            )}

            {!formik.values.internal && formik.values.singleClient && (
              <TextField
                fullWidth
                id="clientName"
                type="clientName"
                variant="outlined"
                name="clientName"
                label="Nome do cliente"
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
                value={formik.values.clientName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.clientName && Boolean(formik.errors.clientName)
                }
                helperText={
                  formik.touched.clientName && formik.errors.clientName
                }
              />
            )}

            <Autocomplete
              options={users.map((item) => ({
                label: `${item.name} - ${item.email}`,
                value: item.id,
              }))}
              fullWidth
              multiple
              limitTags={1}
              filterOptions={(item) =>
                item.filter((user) => !formik.values.user.includes(user.value))
              }
              onChange={handleChangeUser}
              onBlur={formik.handleBlur}
              noOptionsText="Nenhuma opção encontrada"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  id="user"
                  variant="outlined"
                  name="user"
                  label="Usuários"
                  value={formik.values.user}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  helperText={formik.touched.user && formik.errors.user}
                  InputLabelProps={{
                    style: {
                      fontSize: 18,
                      textTransform: 'uppercase',
                      background: '#fff',
                      paddingRight: 5,
                      color: '#000',
                    },
                    shrink: true,
                  }}
                />
              )}
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDate"
              type="date"
              variant="outlined"
              name="startDate"
              label="Data"
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
              hiddenLabel
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="startDateTime"
              type="time"
              variant="outlined"
              name="startDateTime"
              label="Hora de início"
              hiddenLabel
              value={formik.values.startDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateTime &&
                Boolean(formik.errors.startDateTime)
              }
              helperText={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="type"
              variant="outlined"
              name="type"
              label="Tipo do atendimento"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              select
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            >
              <MenuItem value={TypeRequestEnum.REMOTO}>Remoto</MenuItem>
              <MenuItem value={TypeRequestEnum.PRESENTIAL}>Presencial</MenuItem>
            </TextField>
          </StyledContentTextFields>

          <FormGroup>
            <FormControlLabel
              checked={formik.values.repeat}
              onChange={(e, checked) => formik.setFieldValue('repeat', checked)}
              control={<Switch />}
              label="Tarefa recorrente"
            />
          </FormGroup>

          {formik.values.repeat && (
            <TextField
              fullWidth
              id="repeatOften"
              variant="outlined"
              name="repeatOften"
              label="Recorrência"
              value={formik.values.repeatOften}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.repeatOften && Boolean(formik.errors.repeatOften)
              }
              helperText={
                formik.touched.repeatOften && formik.errors.repeatOften
              }
              select
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            >
              <MenuItem value={RecurrencyRequestEnum.DAILY}>
                Todos os dias
              </MenuItem>
              <MenuItem value={RecurrencyRequestEnum.WEEKLY}>Semanal</MenuItem>
              <MenuItem value={RecurrencyRequestEnum.MONTHLY}>Mensal</MenuItem>
              <MenuItem value={RecurrencyRequestEnum.YEARLY}>Anual</MenuItem>
            </TextField>
          )}

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={15}
            name="description"
            label="Descrição do serviço"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/tasks')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cadastrar'}
          </Button>{' '}
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default AddTaskForm;
