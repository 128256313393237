// src/components/PieChart.js
import React, { ReactElement } from 'react';

import { VictoryLabel, VictoryPie } from 'victory';

interface Data {
  value: string | number;
  label: string;
  color: string;
}
interface Props {
  total: string | number;
  data: Data[];
}

function VictoryPieChart({ data, total }: Props): ReactElement {
  return (
    <svg width={200} height={200}>
      <VictoryPie
        standalone={false}
        innerRadius={85}
        data={data.map((item) => ({
          x: item.label,
          y: item.value,
        }))}
        colorScale={data.map((item) => item.color)}
        width={200}
        height={200}
        labelRadius={100}
        style={{ labels: { fontSize: 0 } }}
        animate={{
          duration: 2000,
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        style={[{ fontSize: 20, fontWeight: 'bold' }, { fontSize: 20 }]}
        x={100}
        y={100}
        text={[`${total}`, 'Total']}
      />
    </svg>
  );
}
export default VictoryPieChart;
