/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  RequestFormProps,
  TypeRequestEnum,
  initialRequestFormValues,
  validationSchema,
} from './schema';
import { formatClientType } from '../../pages/Clients/utils';

interface Props {
  users: any[];
  isLoading: boolean;
  task: any;
  onSubmit: (values: RequestFormProps) => void;
}

const EditTaskForm: React.FC<Props> = ({
  users,
  isLoading,
  onSubmit,
  task,
}): React.ReactElement => {
  const navigate = useNavigate();

  const prepareTime = (date: Date): string => {
    const startTime = new Date(date);

    const hours =
      startTime.getHours() >= 10
        ? startTime.getHours()
        : `0${startTime.getHours()}`;

    const minutes =
      startTime.getMinutes() >= 10
        ? startTime.getMinutes()
        : `0${startTime.getMinutes()}`;

    return `${hours}:${minutes}`;
  };

  const prepareOnlyDate = (date: Date): string => {
    const dateParse = new Date(date);

    const day =
      dateParse.getDate() >= 10
        ? dateParse.getDate()
        : `0${dateParse.getDate()}`;

    const month =
      dateParse.getMonth() + 1 >= 10
        ? dateParse.getMonth() + 1
        : `0${dateParse.getMonth() + 1}`;

    const year =
      dateParse.getFullYear() >= 10
        ? dateParse.getFullYear()
        : `0${dateParse.getFullYear()}`;

    return `${year}-${month}-${day}`;
  };

  const initialValues = useMemo(() => {
    if (task) {
      const startDateTime = prepareTime(task.startDate);

      return {
        ...task,
        startDate: prepareOnlyDate(task.startDate),
        startDateTime,
        user: task.user?.map((item: any) => item._id),
      };
    }

    return initialRequestFormValues;
  }, [task]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const handleChangeClient = (event: any, value: any) => {
    formik.setFieldValue('client', value?.value || '');
  };

  const handleChangeUser = (_event: any, value: any) => {
    formik.setFieldValue('user', value?.map((item: any) => item.value) || []);
  };

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <FormGroup>
            <FormControlLabel
              checked={task?.internal}
              disabled
              control={<Switch />}
              label="Tarefa interna"
            />
          </FormGroup>

          <StyledContentTextFields>
            {!!task?.client && (
              <Autocomplete
                options={[
                  {
                    label: `${task.client.name} ${formatClientType(
                      task.client.type
                    )}`,
                    value: task.client._id,
                  },
                ]}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                fullWidth
                disabled={true}
                onChange={handleChangeClient}
                defaultValue={{
                  label: `${task.client.name} ${formatClientType(
                    task.client.type
                  )}`,
                  value: task.client._id,
                }}
                onBlur={formik.handleBlur}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="client"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontSize: 18,
                        textTransform: 'uppercase',
                        background: '#fff',
                        paddingRight: 5,
                        color: '#000',
                      },
                      shrink: true,
                    }}
                    name="client"
                    label="Cliente"
                    value={formik.values.client}
                    error={
                      formik.touched.client && Boolean(formik.errors.client)
                    }
                    helperText={formik.touched.client && formik.errors.client}
                  />
                )}
              />
            )}

            {!!task?.user?.length && (
              <Autocomplete
                options={users.map((item) => ({
                  label: `${item.name} - ${item.email}`,
                  value: item.id,
                }))}
                filterOptions={(item) =>
                  item.filter(
                    (user) => !formik.values.user.includes(user.value)
                  )
                }
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                defaultValue={
                  task
                    ? task?.user.map((item: any) => ({
                        label: `${item.name} - ${item.email}`,
                        value: item._id,
                      }))
                    : undefined
                }
                // defaultValue={task?.user.map((item: any) => item.id)}
                fullWidth
                multiple
                limitTags={1}
                onChange={handleChangeUser}
                onBlur={formik.handleBlur}
                noOptionsText="Nenhuma opção encontrada"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    id="user"
                    variant="outlined"
                    name="user"
                    label="Usuários"
                    value={formik.values.user}
                    error={formik.touched.user && Boolean(formik.errors.user)}
                    helperText={formik.touched.user && formik.errors.user}
                    InputLabelProps={{
                      style: {
                        fontSize: 18,
                        textTransform: 'uppercase',
                        background: '#fff',
                        paddingRight: 5,
                        color: '#000',
                      },
                      shrink: true,
                    }}
                  />
                )}
              />
            )}
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDate"
              type="date"
              variant="outlined"
              name="startDate"
              label="Data"
              inputProps={{
                min: new Date().toISOString().split('T')[0],
              }}
              hiddenLabel
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="startDateTime"
              type="time"
              variant="outlined"
              name="startDateTime"
              label="Hora de início"
              hiddenLabel
              value={formik.values.startDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateTime &&
                Boolean(formik.errors.startDateTime)
              }
              helperText={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="type"
              variant="outlined"
              name="type"
              label="Tipo do atendimento"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
              select
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            >
              <MenuItem value={TypeRequestEnum.REMOTO}>Remoto</MenuItem>
              <MenuItem value={TypeRequestEnum.PRESENTIAL}>Presencial</MenuItem>
            </TextField>
          </StyledContentTextFields>

          <FormGroup>
            <FormControlLabel
              checked={!!task?.repeatOften}
              control={<Switch />}
              disabled
              label="Tarefa recorrente"
            />
          </FormGroup>

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={15}
            name="description"
            label="Descrição do serviço"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/tasks')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cadastrar'}
          </Button>{' '}
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default EditTaskForm;
