import React, { ReactElement } from 'react';

interface NotFoundItemsProps {
  label?: string;
}

const NotFoundItems: React.FC<NotFoundItemsProps> = ({
  label = 'Nenhum item cadastrado !',
}): ReactElement => {
  return (
    <div
      style={{
        background: '#FFF',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        gap: 8,
        flexDirection: 'column',
        boxSizing: 'border-box',
        borderRadius: 8,
        color: '#777',
      }}
    >
      <img style={{ width: 250 }} src="./images/search.png" />
      {label}
    </div>
  );
};

export default NotFoundItems;
