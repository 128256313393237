import styled from 'styled-components';

export const StyledRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const StyledContainer = styled.div`
  width: 100%;
  padding: 100px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #777;
  margin-top: 30px;
`;
