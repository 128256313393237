/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import useAuthenticate from '../../recoil/hooks/authenticate';

import Card from '../../components/Card';
import { removeMask } from '../../utils/mask';
import {
  editClosingRequest,
  getClosingRequestDetails,
} from '../../api/closing';
import EditClosingRequestForm from '../../components/EditClosingRequestForm';
import { StyledRoot } from './styles';
import Backbutton from '../../components/Backbutton';

const ClosingRequestDetailsEdit: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { closingId, requestId } = useParams();

  const [requestDetails, setRequestDetails] = useState<any>({});

  const getData = useCallback(
    async (closingId: string, requestId: string) => {
      try {
        if (authenticate.token) {
          const data = await getClosingRequestDetails(
            authenticate.token,
            closingId,
            requestId
          );

          setRequestDetails(data?.data || {});
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (closingId && requestId) {
      getData(closingId, requestId);
    }
  }, [getData, closingId, requestId]);

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmit = async (values: any) => {
    try {
      if (closingId && requestId) {
        const startDateClosing = prepareDate(
          values.startDateClosing,
          values.startDateTime
        );
        const endDateClosing = prepareDate(
          values.endDateClosing,
          values.endDateTime
        );

        await editClosingRequest(
          {
            ...values,
            duration: values.duration * 60,
            food: Number(removeMask(values.food)),
            taxRoad: Number(removeMask(values.taxRoad)),
            parking: Number(removeMask(values.parking)),
            accomodation: Number(removeMask(values.accomodation)),
            visit: Number(removeMask(values.visit)),
            mileage: Number(removeMask(values.mileage)),
            valuePerHour: Number(removeMask(values.valueHour)),
            valuePerExtraHour: Number(removeMask(values.extraValueHour)),
            valueMileage: Number(removeMask(values.valueMileage)),
            weekendValueHour: Number(removeMask(values.weekendValueHour)),
            startDateClosing,
            endDateClosing,
          },
          authenticate.token,
          closingId,
          requestId
        );

        navigate(`/dash/closing/${closingId}?request=${requestId}`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path={`/dash/closing/${closingId}`}
        labels={['Fechamentos', 'Detalhes do fechamento', 'Atendimento']}
      />

      <Card>
        <EditClosingRequestForm
          isLoading={false}
          onSubmit={handleSubmit}
          requestDetails={requestDetails}
        />
      </Card>
    </StyledRoot>
  );
};

export default ClosingRequestDetailsEdit;
