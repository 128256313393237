import * as yup from 'yup';

export const initialLoginFormValues = {
  email: '',
  password: '',
};

export interface LoginFormProps {
  email: string;
  password: string;
}

export const validationSchema = yup.object({
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  password: yup
    .string()
    .min(8, 'Senha deve ter no mínimo 8 caracteres')
    .required('Campo obrigatório'),
});
