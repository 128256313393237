export const formatDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatShortDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options)
    .format(new Date(date))
    .replace(',', '');
};

export const formatOnlyDate = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const formatOnlyDateWithDayOfWeek = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    weekday: 'short',
  };

  return new Intl.DateTimeFormat('pt-BR', options)
    .format(new Date(date))
    .toUpperCase()
    .replace('.,', ',');
};

export const formatOnlyHour = (date: Date | undefined): string => {
  if (!date) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
  };

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(date));
};

export const calculateDiffDatesInSeconds = (d1: string, d2: string): number => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const diff = (date2.getTime() - date1.getTime()) / 1000;

  return Math.abs(Math.round(diff));
};

export const formatSeconds = (time: number | string): string => {
  const seconds = Number(time);
  const isNegative = seconds < 0;
  const absSeconds = Math.abs(seconds);

  const hours = Math.floor(absSeconds / 3600);
  const minutes = Math.floor((absSeconds % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const formattedTime = `${formattedHours}h:${formattedMinutes}m`;
  return isNegative ? `-${formattedTime}` : formattedTime;
};
