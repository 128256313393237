import React from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import { HubFormProps, initialHubFormValues, validationSchema } from './schema';
import { formatClientType } from '../../pages/Clients/utils';

interface Props {
  clients: any[];
  isLoading: boolean;
  onSubmit: (values: HubFormProps) => void;
}

const AddHubForm: React.FC<Props> = ({
  clients,
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialHubFormValues,
    validationSchema,
    onSubmit,
  });

  const handleChangeClient = (event: any, value: any) => {
    formik.setFieldValue('client', value?.value || '');
  };

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <Autocomplete
            options={clients.map((item) => ({
              label: `${item.name} ${formatClientType(item.type)}`,
              value: item.id,
            }))}
            fullWidth
            onChange={handleChangeClient}
            onBlur={formik.handleBlur}
            noOptionsText="Nenhuma opção encontrada"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                id="client"
                variant="outlined"
                name="client"
                label="Cliente"
                value={formik.values.client}
                error={formik.touched.client && Boolean(formik.errors.client)}
                helperText={formik.touched.client && formik.errors.client}
                InputLabelProps={{
                  style: {
                    fontSize: 18,
                    textTransform: 'uppercase',
                    background: '#fff',
                    paddingRight: 5,
                    color: '#000',
                  },
                  shrink: true,
                }}
              />
            )}
          />

          <TextField
            fullWidth
            id="url"
            variant="outlined"
            name="url"
            label="URL ou IP"
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
          />

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="name"
              type="text"
              variant="outlined"
              name="name"
              label="Nome"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="folder"
              type="text"
              variant="outlined"
              name="folder"
              label="Descrição"
              value={formik.values.folder}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.folder && Boolean(formik.errors.folder)}
              helperText={formik.touched.folder && formik.errors.folder}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <StyledContentTextFields>
            <TextField
              fullWidth
              id="username"
              type="text"
              variant="outlined"
              name="username"
              label="Usuário"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="password"
              type="password"
              variant="outlined"
              name="password"
              label="Senha"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={4}
            name="description"
            label="Notas"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/tasks')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cadastrar'}
          </Button>{' '}
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default AddHubForm;
