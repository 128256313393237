import React from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, MenuItem, TextField } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  LoginFormProps,
  UserRoleEnum,
  initialLoginFormValues,
  validationSchema,
} from './schema';

interface Props {
  isLoading: boolean;
  user: any;
  onSubmit: (values: LoginFormProps) => void;
}

const EditUserForm: React.FC<Props> = ({
  isLoading,
  onSubmit,
  user,
}): React.ReactElement => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: user || initialLoginFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <TextField
            fullWidth
            id="name"
            variant="outlined"
            name="name"
            label="Nome"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            id="email"
            type="email"
            variant="outlined"
            name="email"
            disabled
            label="E-mail"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {/* <TextField
            fullWidth
            id="password-user"
            variant="outlined"
            name="password"
            label="Senha"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          /> */}

          <TextField
            fullWidth
            id="role-user"
            variant="outlined"
            name="role"
            label="Permissão"
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.role && Boolean(formik.errors.role)}
            helperText={formik.touched.role && formik.errors.role}
            select
          >
            <MenuItem value={UserRoleEnum.USER}>Usuário</MenuItem>
            <MenuItem value={UserRoleEnum.ADMIN}>Administrador</MenuItem>
          </TextField>
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/users')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default EditUserForm;
