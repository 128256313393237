/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { Button, CircularProgress, TextField } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import {
  StyledContentButtons,
  StyledContentInputs,
  StyledContentTextFields,
  StyledForm,
  StyledRoot,
} from './styles';
import {
  TaskInternalFinishFormProps,
  initialTaskInternalFinishFormValues,
  validationSchema,
} from './schema';

interface Props {
  task?: any;
  isLoading: boolean;
  onSubmit: (values: TaskInternalFinishFormProps) => void;
}

const TaskInternalFinishForm: React.FC<Props> = ({
  task,
  isLoading,
  onSubmit,
}): React.ReactElement => {
  const navigate = useNavigate();

  const prepareTime = (date: Date): string => {
    const startTime = new Date(date);

    const hours =
      startTime.getHours() >= 10
        ? startTime.getHours()
        : `0${startTime.getHours()}`;

    const minutes =
      startTime.getMinutes() >= 10
        ? startTime.getMinutes()
        : `0${startTime.getMinutes()}`;

    return `${hours}:${minutes}`;
  };

  const prepareOnlyDate = (date: Date): string => {
    const dateParse = new Date(date);

    const day =
      dateParse.getDate() >= 10
        ? dateParse.getDate()
        : `0${dateParse.getDate()}`;

    const month =
      dateParse.getMonth() + 1 >= 10
        ? dateParse.getMonth() + 1
        : `0${dateParse.getMonth() + 1}`;

    const year =
      dateParse.getFullYear() >= 10
        ? dateParse.getFullYear()
        : `0${dateParse.getFullYear()}`;

    return `${year}-${month}-${day}`;
  };

  const initialValues = useMemo(() => {
    if (task) {
      const startDateTime = prepareTime(task.startDate);

      return {
        startDateTime: startDateTime as any,
        startDate: prepareOnlyDate(task.startDate),
      };
    }

    return initialTaskInternalFinishFormValues;
  }, [task]);

  const formik = useFormik({
    initialValues: task
      ? { ...initialTaskInternalFinishFormValues, ...initialValues }
      : initialTaskInternalFinishFormValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <StyledRoot>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledContentInputs>
          <StyledContentTextFields>
            <TextField
              fullWidth
              id="startDate"
              type="date"
              variant="outlined"
              name="startDate"
              label="Data de início"
              inputProps={{
                max: task ? undefined : new Date().toISOString().split('T')[0],
              }}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
              hiddenLabel
              value={formik.values.startDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDate && Boolean(formik.errors.startDate)
              }
              helperText={formik.touched.startDate && formik.errors.startDate}
            />

            <TextField
              fullWidth
              id="startDateTime"
              type="time"
              variant="outlined"
              name="startDateTime"
              label="Hora de início"
              hiddenLabel
              value={formik.values.startDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.startDateTime &&
                Boolean(formik.errors.startDateTime)
              }
              helperText={
                formik.touched.startDateTime && formik.errors.startDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDate"
              type="date"
              variant="outlined"
              name="endDate"
              label="Data de fim"
              hiddenLabel
              value={formik.values.endDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endDate && Boolean(formik.errors.endDate)}
              helperText={formik.touched.endDate && formik.errors.endDate}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="endDateTime"
              type="time"
              variant="outlined"
              name="endDateTime"
              label="Hora de fim"
              hiddenLabel
              value={formik.values.endDateTime}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.endDateTime && Boolean(formik.errors.endDateTime)
              }
              helperText={
                formik.touched.endDateTime && formik.errors.endDateTime
              }
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  textTransform: 'uppercase',
                  background: '#fff',
                  paddingRight: 5,
                  color: '#000',
                },
                shrink: true,
              }}
            />
          </StyledContentTextFields>

          <TextField
            fullWidth
            id="description"
            type="description"
            variant="outlined"
            multiline
            rows={8}
            name="description"
            label="Resolução"
            value={formik.values.description}
            InputLabelProps={{
              style: {
                fontSize: 18,
                textTransform: 'uppercase',
                background: '#fff',
                paddingRight: 5,
                color: '#000',
              },
              shrink: true,
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </StyledContentInputs>

        <StyledContentButtons>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
              background: '#eee',
            }}
            onClick={() => navigate('/dash/requests')}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Cancelar'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={isLoading}
            style={{
              fontWeight: 'bold',
              letterSpacing: 1,
              fontSize: 16,
              height: 50,
            }}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </StyledContentButtons>
      </StyledForm>
    </StyledRoot>
  );
};

export default TaskInternalFinishForm;
