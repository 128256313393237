import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Card from '../../components/Card';
import { getClients } from '../../api/client';
import Backbutton from '../../components/Backbutton';
import AddHubForm from '../../components/AddHubForm';
import { addHub } from '../../api/hub';

const AddHub: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);

  const getClientsData = useCallback(async () => {
    try {
      if (authenticate.token) {
        const data = await getClients(authenticate.token);

        setClients(data?.clients || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getClientsData();
  }, [getClientsData]);
  const handleSubmitAddTask = async (values: any) => {
    try {
      await addHub(values, authenticate.token);

      navigate('/dash/hub');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton
        path="/dash/hub"
        labels={['Base de conhecimento', 'Novo registro']}
      />

      <Card>
        <AddHubForm
          isLoading={false}
          onSubmit={handleSubmitAddTask}
          clients={clients}
        />
      </Card>
    </StyledRoot>
  );
};

export default AddHub;
