import * as yup from 'yup';

export const initialTaskInternalFinishFormValues = {
  startDate: null,
  startDateTime: null,
  endDate: null,
  endDateTime: null,
  description: '',
};

export interface TaskInternalFinishFormProps {
  startDate: string | null;
  startDateTime: string | null;
  endDate: string | null;
  endDateTime: string | null;
  description: string;
}

export const validationSchema = yup.object({
  startDate: yup.date().required('Campo obrigatório').nullable(),
  startDateTime: yup.string().required('Campo obrigatório').nullable(),
  endDate: yup.date().required('Campo obrigatório').nullable(),
  endDateTime: yup.string().required('Campo obrigatório').nullable(),
  description: yup.string().required('Campo obrigatório'),
});
