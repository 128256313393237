import React, { ReactElement } from 'react';

import { StyledRoot } from './styles';
import NotificationDot from '../../NotificationDot';
import MenuOptions from '../../Menu';

interface TableBodyColumnsProps {
  key: string;
  subkey?: string;
  transform?: (text: string) => string;
  transformBody?: (data: any) => ReactElement | string;
  transformIcon?: (text: any) => ReactElement | string;
}

interface TableOptions {
  label: string;
  onClick: (item: any) => void;
}

interface TableBodyProps {
  rows?: TableBodyColumnsProps[];
  onClick?: (data: any) => void;
  data?: any[];
  showNotification?: (data: any) => string;
  options?: TableOptions[];
}

const TableBody: React.FC<TableBodyProps> = ({
  rows,
  data,
  options,
  onClick,
  showNotification,
}): ReactElement => {
  const handleValue = (
    data: any,
    key: TableBodyColumnsProps
  ): string | ReactElement => {
    const value = key.subkey ? data[key.key]?.[key.subkey] : data[key.key];

    if (key.transformBody) {
      return key.transformBody(data);
    }

    if (key.transformIcon) {
      return key.transformIcon(value);
    }

    if (key.transform) {
      return key.transform(value);
    }

    return value;
  };

  const handleClick = (dataItem: any) => {
    if (onClick) {
      onClick(dataItem);
    }
  };

  return (
    <StyledRoot>
      {data?.map((dataItem, index) => (
        <div
          onClick={() => handleClick(dataItem)}
          key={index}
          style={{
            padding: 8,
            marginBottom: 8,
            background: '#fff',
            display: 'flex',
            width: '100%',
            borderRadius: 8,
            cursor: onClick ? 'pointer' : undefined,
            boxSizing: 'border-box',
            boxShadow: 'rgba(0, 0, 0, 0.25) 5px -3px 7px 0px',
            position: 'relative',
          }}
        >
          {rows?.map((key, index) => (
            <div
              key={index}
              style={{
                flex: 1,
                textAlign: 'center',
                margin: 10,
                fontSize: 18,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {handleValue(dataItem, key)}
            </div>
          ))}

          {!!options?.length && (
            <div
              style={{
                width: 100,
                textAlign: 'center',
                margin: 10,
                fontSize: 18,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <MenuOptions item={dataItem} options={options} />
            </div>
          )}

          {showNotification && showNotification(dataItem) && (
            <div style={{ position: 'absolute', top: 5, right: 5 }}>
              <NotificationDot color={showNotification(dataItem)} />
            </div>
          )}
        </div>
      ))}
    </StyledRoot>
  );
};

export default TableBody;
