import React, { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';
import { StyledRoot } from './styles';
import { addClient } from '../../api/client';
import useAuthenticate from '../../recoil/hooks/authenticate';
import AddClientForm from '../../components/AddClientForm';
import Card from '../../components/Card';
import { removeMask } from '../../utils/mask';
import Backbutton from '../../components/Backbutton';

const AddClient: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const prepareDate = (date: Date, time: string): Date => {
    const [hour, minute] = time.split(':');

    const newDate = new Date(date);

    newDate.setDate(newDate.getDate() + 1);

    newDate.setHours(Number(hour));
    newDate.setMinutes(Number(minute));

    return newDate;
  };

  const handleSubmitAddClient = async (values: any) => {
    try {
      const startDate = prepareDate(values.startDateService, '23:59');

      await addClient(
        {
          ...values,
          identification: removeMask(values.identification),
          phoneOne: removeMask(values.phoneOne),
          phoneTwo: removeMask(values.phoneTwo),

          cep: removeMask(values.cep),
          food: removeMask(values.food),
          taxRoad: removeMask(values.taxRoad),
          parking: removeMask(values.parking),
          accomodation: removeMask(values.accomodation),
          visit: removeMask(values.visit),
          mileage: removeMask(values.mileage),
          valueHour: removeMask(values.valueHour),
          extraValueHour: removeMask(values.extraValueHour),
          contractValue: removeMask(values.contractValue),
          weekendValueHour: removeMask(values.weekendValueHour),
          valueMileage: removeMask(values.valueMileage),
          startDateService: startDate,
        },
        authenticate.token
      );

      navigate('/dash/clients');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return (
    <StyledRoot>
      <Backbutton path="/dash/clients" labels={['Clientes', 'Novo cliente']} />

      <Card>
        <AddClientForm isLoading={false} onSubmit={handleSubmitAddClient} />
      </Card>
    </StyledRoot>
  );
};

export default AddClient;
