import styled from 'styled-components';

export const StyledRoot = styled('div')({
  width: '100%',
  height: 275,
  borderRadius: 8,
  padding: 16,
  position: 'relative',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #ccc',
  background: '#fff',
  // boxShadow: '0 0 13px 0px rgba(0, 0, 0, 0.25)',
  // boxShadow: 'rgba(0, 0, 0, 0.25) 5px -3px 7px 0px',
  boxShadow: 'rgba(0, 0, 0, 0.25) 6px -3px 8px 2px',
  gap: 16,
});

export const StyledContainer = styled('div')({
  flex: 1,
  display: 'flex',
  gap: 10,
});

export const StyledBar = styled('div')({
  width: 5,
  height: '100%',
  background: '#38a658',
  borderRadius: 4,
});

export const StyledContentMiddle = styled('div')({
  flex: 1,

  display: 'flex',
  flexDirection: 'column',
});

export const StyledContentIcon = styled('div')({
  padding: 10,
  boxSizing: 'border-box',
  borderRadius: 48,
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#38A65833',
  gap: 16,
});

export const StyledTitle = styled('div')({
  color: '#3D4043',
  fontSize: 22,
  letterSpacing: 1,
  fontFamily: 'Lato',
});

export const StyledDescription = styled('div')({
  color: '#2E2E2E',
  fontSize: 24,
  fontWeight: 'bold',
  display: 'flex',
  //   justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
});
