import { useRecoilState } from 'recoil';

import authenticateAtom from '../atoms/authenticate';

const decodeToken = (
  token: string
): { id: string; name: string; role: string } => {
  const first = token.split('.');
  const second = first[1];
  const teste = second.replace('-', '+').replace('_', '/');
  const third = decodeURIComponent(escape(atob(teste)));
  const fourth = JSON.parse(third);

  return fourth;
};

const useAuthenticate = (): any => {
  const [authenticate, setAuthenticate] = useRecoilState(authenticateAtom);

  const signin = (token: string) => {
    const decode = decodeToken(token);
    setAuthenticate({
      isAuthenticate: true,
      token,
      user: decode,
    });
  };

  const logout = () => {
    setAuthenticate({
      isAuthenticate: false,
      token: '',
      user: null,
    });
  };

  return { authenticate, setAuthenticate, signin, logout };
};

export default useAuthenticate;
