import React, { ReactElement, ReactNode } from 'react';

import { StyledRoot } from './styles';
import TableRow from '../TableRow';

interface TableBodyColumnsProps {
  key: string;
  subkey?: string;
  transform?: (text: string) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement | string;
}

interface TableOptions {
  label: string;
  onClick: (item: any) => void;
}

interface TableBodyProps {
  rows?: TableBodyColumnsProps[];
  onClick?: (data: any) => void;
  data?: any[];
  expandAll?: boolean;
  defaultExpansive?: string;
  handleExpansive?: (item: any) => ReactNode;
  showNotification?: (data: any) => string;
  options?: TableOptions[];
}

const TableBody: React.FC<TableBodyProps> = ({
  rows,
  data,
  defaultExpansive,
  expandAll,
  options,
  showNotification,
  handleExpansive,
}): ReactElement => {
  return (
    <StyledRoot>
      {data?.map((dataItem, index) => (
        <TableRow
          key={index}
          showNotification={showNotification}
          dataItem={dataItem}
          rows={rows}
          expandAll={expandAll}
          options={options}
          defaultExpansive={defaultExpansive}
          handleExpansive={handleExpansive}
        />
      ))}
    </StyledRoot>
  );
};

export default TableBody;
