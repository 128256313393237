/* eslint-disable no-underscore-dangle */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, Collapse, IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NotificationDot from '../../NotificationDot';
import MenuOptions from '../../Menu';

interface TableRowColumnsProps {
  key: string;
  subkey?: string;
  transform?: (text: string) => string;
  transformBody?: (data: any) => string;
  transformIcon?: (text: any) => ReactElement | string;
}

interface TableOptions {
  label: string;
  onClick: (item: any) => void;
}

interface TableRowProps {
  rows?: TableRowColumnsProps[];
  onClick?: (data: any) => void;
  dataItem?: any;
  expandAll?: boolean;
  defaultExpansive?: string;
  handleExpansive?: (item: any) => ReactNode;
  showNotification?: (data: any) => string;
  options?: TableOptions[];
}

const TableRow: React.FC<TableRowProps> = ({
  rows,
  dataItem,
  defaultExpansive,
  expandAll,
  options,
  handleExpansive,
  showNotification,
}): ReactElement => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (expandAll === true || expandAll === false) {
      setOpen(expandAll);
    }
  }, [expandAll]);

  useEffect(() => {
    if (defaultExpansive === dataItem._id) {
      setOpen(true);
    }
  }, [dataItem._id, defaultExpansive]);

  const handleValue = (
    data: any,
    key: TableRowColumnsProps
  ): string | ReactElement => {
    const value = key.subkey ? data[key.key]?.[key.subkey] : data[key.key];

    if (key.transformBody) {
      return key.transformBody(data);
    }

    if (key.transformIcon) {
      return key.transformIcon(value);
    }

    if (key.transform) {
      return key.transform(value);
    }

    return value;
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setOpen((previus) => !previus)}
        style={{
          marginBottom: 8,
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          borderRadius: 8,
          position: 'relative',
          boxSizing: 'border-box',
          boxShadow: 'rgba(0, 0, 0, 0.25) 5px -3px 7px 0px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: 1,
            padding: 8,
            boxSizing: 'border-box',
            backgroundColor: open ? `#38A65833` : '#fff',
            transition: '0.4s',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            cursor: 'pointer',
          }}
        >
          {rows?.map((key, index) => (
            <div
              key={index}
              style={{
                flex: 1,
                textAlign: 'center',
                margin: 10,
                fontSize: 18,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {handleValue(dataItem, key)}
            </div>
          ))}
          <div
            style={{
              display: 'flex',
              flex: 1,
              textAlign: 'center',
              margin: 10,
              gap: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!!options?.length && (
              <div
                style={{
                  textAlign: 'center',
                  fontSize: 18,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <MenuOptions item={dataItem} options={options} />
              </div>
            )}
            <div style={{}}>
              <IconButton aria-label="expand row" size="small">
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ background: '#fff' }} onClick={(e) => e.stopPropagation()}>
            {handleExpansive && handleExpansive(dataItem)}
          </Box>
        </Collapse>
        {showNotification && showNotification(dataItem) && (
          <div style={{ position: 'absolute', top: 5, right: 5 }}>
            <NotificationDot color={showNotification(dataItem)} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TableRow;
