import React, { ReactElement } from 'react';

import { StyledRoot } from './styles';

interface TableHeaderColumnsProps {
  label: string;
}

interface TableHeaderProps {
  color?: string;
  backgroundColor?: string;
  columns?: TableHeaderColumnsProps[];
}

const TableHeader: React.FC<TableHeaderProps> = ({
  color,
  backgroundColor,
  columns,
}): ReactElement => {
  return (
    <StyledRoot
      style={{
        color,
        backgroundColor,
      }}
    >
      {columns?.map((item, index) => (
        <div
          key={index}
          style={{
            flex: 1,
            textAlign: 'center',
            padding: 8,
            boxSizing: 'border-box',
            fontSize: 18,
          }}
        >
          {item.label}
        </div>
      ))}
      <div
        style={{
          flex: 1,
          textAlign: 'center',
          padding: 8,
          boxSizing: 'border-box',
          fontSize: 18,
        }}
      >
        Ações
      </div>
    </StyledRoot>
  );
};

export default TableHeader;
