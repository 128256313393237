import api from '../services/api';

export const getHubClients = async (token: string): Promise<any> => {
  const result = await api.get(
    '/api/hub/clients',

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getHubClient = async (
  clientId: string,
  token: string
): Promise<any> => {
  const result = await api.get(
    `/api/hub/clients/${clientId}`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

interface AddHubRequest {
  client: string;
  description: string;
  folder: string;
  name: string;
  password: string;
  url: string;
  username: string;
}

export const addHub = async (
  data: AddHubRequest,
  token: string
): Promise<void> => {
  await api.post('/api/hub', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getHubClients,
  addHub,
};
