import React, { ReactElement } from 'react';

import { StyledRoot } from './styles';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

interface TableHeaderColumnsProps {
  label: string;
}

interface TableHeaderProps {
  color?: string;
  backgroundColor?: string;
  columns?: TableHeaderColumnsProps[];
}

interface TableOptions {
  label: string;
  onClick: (item: any) => void;
}

interface TableBodyRowsProps {
  key: string;
  subkey?: string;
  transform?: (text: any) => string;
  transformBody?: (data: any) => ReactElement | string;
  transformIcon?: (text: any) => ReactElement | string;
}

interface TableBodyProps {
  rows?: TableBodyRowsProps[];
  onClick?: (data: any) => void;
  showNotification?: (data: any) => string;
  options?: TableOptions[];
}

interface TableProps {
  header?: TableHeaderProps;
  body?: TableBodyProps;
  data?: any[];
}

const Table: React.FC<TableProps> = ({ header, body, data }): ReactElement => {
  return (
    <StyledRoot>
      {header && (
        <TableHeader {...header} hasOptions={!!body?.options?.length} />
      )}
      {body && <TableBody {...body} data={data} />}
    </StyledRoot>
  );
};

export default Table;
