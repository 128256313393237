import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StyledRoot } from './styles';
import { deleteUser, getUsers } from '../../api/users';
import useAuthenticate from '../../recoil/hooks/authenticate';
import Table from '../../components/Table';
import TableTitle from '../../components/Table/TableTitle';
import { UserRoleEnum } from '../../components/AddUserForm/schema';
import Backbutton from '../../components/Backbutton';
import ConfirmModal from '../../components/ConfirmModal';
import NotFoundItems from '../../components/NotFoundItems';
import { formatShortDate } from '../../utils/date';
import SmallLoading from '../../components/SmallLoading';

const Users: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const [isLoading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');

  const [users, setUsers] = useState([]);

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      if (authenticate.token) {
        const data = await getUsers(authenticate.token);

        setUsers(data?.users || []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  }, [authenticate.token]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleOpenDeleteModal = (id: string) => {
    setOpen(true);
    setUserToDelete(id);
  };

  const handleDelete = async (id: string) => {
    setOpen(false);
    try {
      if (authenticate.token) {
        await deleteUser(authenticate.token, id);

        getData();
      }

      toast('Usuário deletado com sucesso!', { type: 'success' });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const formatUserPermission = (role: UserRoleEnum): string => {
    const options = {
      [UserRoleEnum.ADMIN]: 'Administrador',
      [UserRoleEnum.USER]: 'Usuário',
    };

    return options[role];
  };

  const usersFiltered = useMemo(() => {
    if (!users) {
      return [];
    }

    if (!search) {
      return users;
    }

    return users.filter((item: any) => {
      const searchUppercase = search.toUpperCase();
      return (
        item.name.toUpperCase().includes(searchUppercase) ||
        item.email.toUpperCase().includes(searchUppercase)
      );
    });
  }, [search, users]);

  return (
    <React.Fragment>
      <StyledRoot>
        <div style={{ display: 'flex', flex: 1 }}>
          <Backbutton path="/dash" labels={['Usuários']} />

          <TableTitle
            title=""
            search={{
              handleSearch: (value: string) => setSearch(value),
            }}
            button={{
              text: 'Novo usuário',
              onAction: () => navigate('/dash/users/create'),
            }}
          />
        </div>

        {isLoading && <SmallLoading />}

        {!isLoading && (
          <Table
            header={{
              backgroundColor: '#38A658',
              color: '#fff',
              columns: [
                {
                  label: 'Nome',
                },
                {
                  label: 'E-mail',
                },
                {
                  label: 'Último acesso',
                },
                {
                  label: 'Permissão',
                },
              ],
            }}
            body={{
              rows: [
                { key: 'name' },
                { key: 'email' },
                { key: 'lastAccess', transform: formatShortDate },
                { key: 'role', transform: formatUserPermission },
              ],
              options: [
                {
                  label: 'Editar',
                  onClick: (item) => navigate(`/dash/users/${item.id}/edit`),
                },
                {
                  label: 'Redefinir senha',
                  onClick: (item) =>
                    navigate(`/dash/users/${item.id}/edit-password`),
                },
                {
                  label: 'Deletar',
                  onClick: (item) => handleOpenDeleteModal(item.id),
                },
              ],
            }}
            data={usersFiltered}
          />
        )}
        {!isLoading && !usersFiltered.length && (
          <NotFoundItems
            label={
              users.length
                ? 'Nenhum usuário encontrado para essa busca!'
                : undefined
            }
          />
        )}
      </StyledRoot>
      <ConfirmModal
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={() => handleDelete(userToDelete)}
        title="Deletar usuário"
        message="Deseja confirmar a exclusão do usuário?"
      />
    </React.Fragment>
  );
};

export default Users;
