import { ClientFormProps } from '../components/AddClientForm/schema';
import api from '../services/api';

export const getClients = async (token: string): Promise<any> => {
  const result = await api.get(
    '/api/clients',

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getClientDetails = async (
  token: string,
  id: string
): Promise<any> => {
  const result = await api.get(
    `/api/clients/${id}`,

    {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const addClient = async (
  data: ClientFormProps,
  token: string
): Promise<void> => {
  await api.post('/api/clients', data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const deleteClient = async (
  token: string,
  id: string
): Promise<void> => {
  await api.delete(`/api/clients/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export const editClient = async (
  id: string,
  data: ClientFormProps,
  token: string
): Promise<void> => {
  await api.patch(`/api/clients/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
    },
  });
};

export default {
  getClients,
  addClient,
  editClient,
  deleteClient,
};
