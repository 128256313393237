/* eslint-disable no-param-reassign */

export const maskCnpj = (v: string): string => {
  v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
  return v.substring(0, 18);
};

export const maskCpf = (v: string): string => {
  v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  // de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
  return v.substring(0, 14);
};

export const maskCnpjOrCpf = (v: string): string => {
  if (!v) {
    return '-';
  }

  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    return maskCpf(v);
  }

  return maskCnpj(v);
};

export const removeMask = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.toString().replace(/\D/g, '');
};
