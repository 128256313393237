/* eslint-disable no-underscore-dangle */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import EditNoteIcon from '@mui/icons-material/EditNote';

import { useNavigate, useParams } from 'react-router-dom';
import { StyledEditRequestContainer, StyledRoot } from './styles';
import useAuthenticate from '../../recoil/hooks/authenticate';

import {
  calculateDiffDatesInSeconds,
  formatDate,
  formatSeconds,
} from '../../utils/date';
import { getRequestDetails } from '../../api/request';
import Card from '../../components/Card';
import DetailsInfo, {
  DetailsSectionRowsProps,
} from '../../components/DetailsInfo';
import { maskCnpjOrCpf } from '../../utils/mask';
import { isCpfValid } from '../../utils/cpf';
import { formatClientType } from '../Clients/utils';
import Backbutton from '../../components/Backbutton';
import { ClientTypeEnum } from '../../components/AddClientForm/schema';
import SmallLoading from '../../components/SmallLoading';

const RequestDetails: React.FC = (): ReactElement => {
  const { authenticate } = useAuthenticate();
  const navigate = useNavigate();

  const { id } = useParams();

  const [isLoading, setLoading] = useState(true);

  const [requestDetails, setRequestDetails] = useState<any>({});

  const getData = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        if (authenticate.token) {
          const data = await getRequestDetails(authenticate.token, id);

          setRequestDetails(data?.data || {});
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [authenticate.token]
  );

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [getData, id]);

  const formatStatus = (status: string): string => {
    if (status === 'pending') {
      return 'Em andamento';
    }

    return 'Finalizado';
  };

  const formatType = (status: string): string => {
    if (status === 'remote') {
      return 'Remoto';
    }

    return 'Presencial';
  };

  return (
    <StyledRoot>
      <Backbutton
        path="/dash/requests"
        labels={['Atendimentos', 'Detalhes do atendimento']}
      />

      {isLoading && <SmallLoading />}

      {!isLoading && (
        <Card>
          <StyledEditRequestContainer
            onClick={() => navigate(`/dash/requests/${id}/edit`)}
          >
            <EditNoteIcon fontSize="large" color="primary" />
            <div>Editar atendimento </div>
          </StyledEditRequestContainer>
          <DetailsInfo
            isLoading={false}
            sections={[
              {
                title: 'Cliente',
                rows: [
                  {
                    label: 'Razão social',
                    key: 'name',
                  },
                  authenticate.user.role === 'admin'
                    ? {
                        label:
                          requestDetails?.client?.identification &&
                          isCpfValid(requestDetails?.client?.identification)
                            ? 'CPF'
                            : 'CNPJ',
                        key: 'identification',
                        transform: maskCnpjOrCpf,
                      }
                    : undefined,
                  authenticate.user.role === 'admin'
                    ? {
                        label: 'Tipo',
                        key: 'type',
                        transform: (value: ClientTypeEnum) =>
                          formatClientType(value, false),
                      }
                    : undefined,
                ].filter((item) => !!item) as DetailsSectionRowsProps[],
                data: requestDetails?.client,
              },
              {
                title: 'Consultor',
                rows: [
                  {
                    label: 'Nome',
                    key: 'name',
                  },
                  {
                    label: 'E-mail',
                    key: 'email',
                  },
                ],
                data: requestDetails?.user,
              },

              {
                title: 'Atendimento',
                rows: [
                  {
                    label: 'Data de início',
                    key: 'startDate',
                    transform: formatDate,
                  },
                  {
                    label: 'Data de fim',
                    key: 'endDate',
                    transform: formatDate,
                  },
                  {
                    label: 'Duração',
                    key: 'endDate',
                    transformBody: (params: any) => {
                      const seconds = calculateDiffDatesInSeconds(
                        params.startDate,
                        params.endDate
                      );
                      return formatSeconds(seconds);
                    },
                  },
                  {
                    label: 'Tipo do atendimento',
                    key: 'type',
                    transform: formatType,
                  },
                  {
                    label: 'Status',
                    key: 'status',
                    transform: formatStatus,
                  },
                  {
                    label: 'Descrição',
                    key: 'description',
                    isHtml: true,
                  },
                  {
                    label: 'Observação (interna)',
                    key: 'observation',
                    isHtml: true,
                  },
                ],
                data: requestDetails,
              },
            ]}
          />
        </Card>
      )}
    </StyledRoot>
  );
};

export default RequestDetails;
