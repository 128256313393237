import styled from 'styled-components';

export const StyledRoot = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',

  padding: 32,
  boxSizing: 'border-box',
});
