import styled from 'styled-components';

export const StyledRoot = styled('div')({
  boxSizing: 'border-box',
  borderRadius: 8,
  padding: 8,
  marginBottom: 8,
  fontSize: 18,
  letterSpacing: 1,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-around',
  // border: '1px solid #ccc',
  // boxShadow: '0 0 13px 0px rgba(0, 0, 0, 0.25)',
  boxShadow: 'rgba(0, 0, 0, 0.25) 5px -3px 7px 0px',
});
