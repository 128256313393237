import styled from 'styled-components';

export const StyledRoot = styled('div')({
  borderRadius: 8,

  boxSizing: 'border-box',
  // border: '1px solid #ccc',
  // background: '#fff',
  // boxShadow: '0 0 13px 0px rgba(0, 0, 0, 0.25)',
});
