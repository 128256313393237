import * as yup from 'yup';

export enum TypeRequestEnum {
  REMOTO = 'remote',
  PRESENTIAL = 'presential',
}

export enum StatusRequestEnum {
  COMPLETED = 'completed',
  PENDING = 'pending',
}

// const requiredKeys = ['clientId', 'type', 'startDate', 'endDate', 'description', 'status'];

export const initialRequestFormValues = {
  client: '',
  clientName: '',
  type: TypeRequestEnum.REMOTO,
  startDate: null,
  startDateTime: null,
  endDate: null,
  endDateTime: null,
  description: '',
  status: StatusRequestEnum.COMPLETED,
  singleClient: false,
  observation: '',
  weekendValueHour: '',
  valueMileage: '',
  chargeRequest: true,
};

export interface RequestFormProps {
  client: string;
  clientName: string;
  startDate: Date | null;
  startDateTime: string | null;
  endDate: Date | null;
  endDateTime: string | null;
  description: string;
  type: TypeRequestEnum;
  status: StatusRequestEnum;
  observation: string;
  singleClient: boolean;

  duration: string;
  valueHour: string;
  extraValueHour: string;
  weekendValueHour: string;
  food: string;
  taxRoad: string;
  parking: string;
  accomodation: string;
  visit: string;
  mileage: string;
  valueMileage: string;
  chargeRequest: boolean;
}

export const validationSchema = yup.object({
  client: yup.string().required('Campo obrigátorio'),
  startDate: yup.date().required('Campo obrigatório').nullable(),
  startDateTime: yup.string().required('Campo obrigatório').nullable(),
  endDate: yup.date().required('Campo obrigatório').nullable(),
  endDateTime: yup.string().required('Campo obrigatório').nullable(),
  description: yup.string().required('Campo obrigatório'),
  observation: yup.string(),
  type: yup
    .mixed<TypeRequestEnum>()
    .oneOf(
      [TypeRequestEnum.REMOTO, TypeRequestEnum.PRESENTIAL],
      'Campo inválido'
    )
    .required('Campo obrigatório'),
  status: yup
    .mixed<StatusRequestEnum>()
    .oneOf(
      [StatusRequestEnum.COMPLETED, StatusRequestEnum.PENDING],
      'Campo inválido'
    )
    .required('Campo obrigatório'),
});
