import React, { ReactElement } from 'react';

import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';

import {
  StyledContainer,
  StyledContentMiddle,
  StyledRoot,
  StyledTitle,
} from './styles';
import VictoryPieChart from './VictoryPie';
import NotificationDot from '../NotificationDot';

Chart.register(CategoryScale);

interface Data {
  value: string | number;
  label: string;
  color: string;
}
interface Props {
  title: string;
  total: string | number;
  data: Data[];
  notification?: {
    color: string;
  };
}

const CardGraph: React.FC<Props> = ({
  title,
  total,
  data,
  notification,
}): ReactElement => {
  return (
    <StyledRoot>
      <StyledContainer>
        <StyledContentMiddle>
          <StyledTitle>{title}</StyledTitle>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 32,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VictoryPieChart data={data} total={total} />
            </div>
            <div
              style={{
                width: 120,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 8,
              }}
            >
              {data.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <div style={{ fontSize: 18 }}>{item.label}</div>
                  <div
                    style={{ display: 'flex', gap: 16, alignItems: 'center' }}
                  >
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        background: item.color,
                        borderRadius: 100,
                      }}
                    ></div>
                    <div style={{ fontWeight: 'bold', fontSize: 18 }}>
                      {item.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </StyledContentMiddle>
      </StyledContainer>
      {notification && (
        <div style={{ position: 'absolute', top: 5, right: 5 }}>
          <NotificationDot color={notification.color} />
        </div>
      )}
    </StyledRoot>
  );
};

export default CardGraph;
